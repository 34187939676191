import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
    position: relative;
`;

const Current = styled.h1`
    text-align: right;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: ${({ theme: { colours } }) => colours.default.PureBlack};
    width: ${({ width }) => (width ? width : "auto")};

    @media screen and (max-width: 768px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        width: auto;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    color: ${({ theme: { colours } }) => colours.default.PureBlack};
    position: relative;

    @media screen and (max-width: 768px) {
        height: 16px;
    }
`;

const Button = styled.button`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 5px 29px;
    gap: 16px;

    border: 3px solid #606060;
    border-radius: 32px;

    position: relative;

    cursor: pointer;

    background: transparent;

    @media screen and (max-width: 768px) {
        border: 2px solid #606060;
        padding: 8px 16px;
    }

    &.clicked {
        ${Icon} {
            &.flip {
                @keyframes flip {
                    from {
                        top: 10px;
                        opacity: 0%;
                    }
                    to {
                        top: 0px;
                        opacity: 100%;
                    }
                }
                animation-duration: 0.2s;
                animation-timing-function: ease-in-out;
                animation-delay: 0s;
                animation-iteration-count: 1;
                animation-direction: normal;
                animation-fill-mode: forwards;
                animation-play-state: running;
                animation-name: flip;
                transform: scaleY(-1);
            }
        }
    }
`;

const Options = styled.div`
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 13px 29px;
    box-sizing: border-box;
    gap: 16px;

    position: absolute;
    width: 100%;
    left: 0px;
    top: 73px;

    border: 3px solid #606060;
    border-radius: 32px;
    background: #ffffff;
    z-index: 1;

    &.active {
        display: flex;
    }

    @media screen and (max-width: 768px) {
        top: 51px;
        border: 2px solid #606060;
        padding: 8px 16px;
        gap: 8px;
    }
`;

const Option = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        gap: 8px;
    }
`;

const OptionText = styled.p`
    text-align: left;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    align-self: stretch;

    color: #606060;

    &:hover {
        color: #000000;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
`;

const Divider = styled.div`
    height: 3px;
    background: #606060;
    border-radius: 2px;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        height: 2px;
    }
`;

export function YearOrder({ options, year }) {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const { t, i18n } = useTranslation("Dropdown");

    const handleOptionClick = (option) => {
        setIsOpen(false);
        navigate({
            pathname: "",
            search: `?${createSearchParams({
                year: option,
            })}`,
        });
    };

    return (
        <Container>
            <Button
                type="button"
                className={isOpen ? "clicked" : ""}
                onClick={(e) => setIsOpen((current) => !current)}
            >
                <Current>
                    {year === "other"
                        ? t("others")
                        : i18n.language === "ko"
                        ? `${year}${t("year")}`
                        : `${t("year")} ${year}`}
                </Current>
                <Icon icon={faAngleDown} className="flip" size="2xl" />
            </Button>
            <Options className={isOpen ? "active" : ""}>
                {options?.map((option, key) => {
                    return (
                        <Option key={key}>
                            <OptionText
                                onClick={(e) => handleOptionClick(option)}
                            >
                                {option === "other"
                                    ? t("others")
                                    : i18n.language === "ko"
                                    ? `${option}${t("year")}`
                                    : `${t("year")} ${option}`}
                            </OptionText>
                            {key + 1 !== options.length && <Divider />}
                        </Option>
                    );
                })}
            </Options>
        </Container>
    );
}

export function RecentOrder({ order }) {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const { t } = useTranslation("Dropdown");
    const options = [true, false];

    const handleOptionClick = (order) => {
        setIsOpen(false);
        navigate({
            pathname: "",
            search: `?${createSearchParams({
                recent: order,
            })}`,
        });
    };

    return (
        <Container>
            <Button
                type="button"
                className={isOpen ? "clicked" : ""}
                onClick={(e) => setIsOpen((current) => !current)}
            >
                <Current>
                    {order === "true" ? t("recent") : t("oldest")}
                </Current>
                <Icon icon={faAngleDown} className="flip" size="2xl" />
            </Button>
            <Options className={isOpen ? "active" : ""}>
                {options?.map((option, key) => {
                    return (
                        <Option key={key}>
                            <OptionText
                                onClick={(e) => handleOptionClick(option)}
                            >
                                {option ? t("recent") : t("oldest")}
                            </OptionText>
                            {key + 1 !== options.length && <Divider />}
                        </Option>
                    );
                })}
            </Options>
        </Container>
    );
}
