import { Link, NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/images/logo.png";

import Login from "./Login";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserProfile } from "../actions/userActions";
import Logout from "./Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { LeftGNBElement, MiddleGNBElement, RightGNBElement } from "./GNBElements";

/**
 * Header container equivalent to &lt;header&gt; tag
 */
const HeaderContainer = styled.header`
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${({ colour, theme: { colours } }) =>
        colour && `background-color: ${colours.default[colour]}`}
`;

/**
 * Main container that has all the header components.
 */
const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0px 30px;
    gap: 16px;

    height: 84px;
    background-color: ${({ theme: { colours } }) =>
        `${colours.default.MistyGrey}`};
    border-radius: 0px 0px 16px 16px;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        gap: normal;
    }
`;

/**
 * Desktop Navigation Container
 */
const DesktopNav = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 30px 0px 0px;
    gap: 22px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

/**
 * Logo style back to home button
 */
const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 64px;
    height: 84px;

    &.mobile {
        display: none;
        @media screen and (max-width: 768px) {
            display: flex;
        }
    }
`;

const Icon = styled(FontAwesomeIcon)`
    width: 32px;
    color: ${({ theme: { colours } }) => `${colours.default.PureWhite}`};
`;

const GNBContainer = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
`;

/**
 * Global Navigation Bar for mobile devices
 */
const MobileGNB = styled.ul`
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 32px;
    gap: 16px;

    align-self: stretch;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
        &.active {
            display: flex;
        }
    }
`;

/**
 * Mobile menu icon container
 */
const MobileMenuIcon = styled.div`
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 84px;

    @media screen and (max-width: 768px) {
        display: flex;
    }
`;

const StyledNavLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-decoration: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    color: ${({ theme: { colours } }) => `${colours.default.MistyGrey}`};

    &.active {
        color: ${({ theme: { colours } }) => `${colours.default.PureBlack}`};
    }
    &:hover {
        color: ${({ theme: { colours } }) => `${colours.default.PureBlack}`};
    }
`;

const Divider = styled.div`
    height: 3px;
    background-color: ${({ theme: { colours } }) =>
        `${colours.default.MistyGrey}`};
    border-radius: 2px;
    align-self: stretch;
`;

function Header() {
    const dispath = useDispatch();
    const path = useLocation().pathname;
    const userLogin = useSelector((state) => state.userLogin);
    const { isLoggedIn } = userLogin;
    const userProfile = useSelector((state) => state.userProfile);
    const { loading, user: profile } = userProfile;
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation("Header");

    useEffect(() => {
        if (!profile && !loading) {
            if (isLoggedIn) {
                dispath(getUserProfile());
            }
        }
    }, [dispath, isLoggedIn]);

    return (
        <HeaderContainer colour={path === "/introduce" && "SkyBlue"}>
            <MainContainer>
                <MobileMenuIcon>
                    <Icon
                        onClick={(e) => setIsOpen((current) => !current)}
                        icon={isOpen ? faXmark : faBars}
                        size="2x"
                    />
                </MobileMenuIcon>
                <LogoLink className="mobile" to="/">
                    <img src={logo} alt="logo" width="64px" height="64px" />
                </LogoLink>
                <DesktopNav>
                    <LogoLink to="/">
                        <img src={logo} alt="logo" width="64px" height="64px" />
                    </LogoLink>
                    <GNBContainer>
                        <LeftGNBElement
                            to="/"
                            text={t("nav.home")}
                            colour={path === "/introduce" && "SkyBlue"}
                        />
                        <MiddleGNBElement
                            to="/introduce"
                            text="UCLKS"
                            colour={path === "/introduce" && "SkyBlue"}
                        />
                        <RightGNBElement
                            to="/module-feedback"
                            text={t("nav.module-feedback")}
                            colour={path === "/introduce" && "SkyBlue"}
                        />
                    </GNBContainer>
                </DesktopNav>
                {profile ? <Logout name={profile?.name} /> : <Login />}
            </MainContainer>
            <MobileGNB className={isOpen ? "active" : ""}>
                <StyledNavLink to="/" onClick={(e) => setIsOpen(false)}>
                    {t("nav.home")}
                </StyledNavLink>
                <Divider />
                <StyledNavLink
                    to="/introduce"
                    onClick={(e) => setIsOpen(false)}
                >
                    UCLKS
                </StyledNavLink>
                <Divider />
                <StyledNavLink
                    to="/module-feedback"
                    onClick={(e) => setIsOpen(false)}
                >
                    {t("nav.module-feedback")}
                </StyledNavLink>
            </MobileGNB>
        </HeaderContainer>
    );
}

export default Header;
