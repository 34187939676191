import * as constants from "../constants/moduleConstants";
import axios from "axios";
window.axios = axios;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;

export const getModule = (id, recent) => async (dispatch) => {
    try {
        dispatch({ type: constants.MODULE_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_PROXY_URL}/api/modules/${id}?recent=${recent}`);
        dispatch({ type: constants.MODULE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.MODULE_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};

export const getModuleAverage = (id) => async (dispatch) => {
    try {
        dispatch({ type: constants.MODULE_AVERAGE_REQUEST });
        const { data } = await axios.get(`${process.env.REACT_APP_PROXY_URL}/api/modules/${id}/average`);
        dispatch({ type: constants.MODULE_AVERAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: constants.MODULE_AVERAGE_FAIL,
            payload:
                error.response && error.response.data.errors
                    ? error.response.data.errors
                    : error.message,
        });
    }
};
