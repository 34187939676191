import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Link } from "react-router-dom";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: stretch;
    column-gap: 30px;

    box-sizing: border-box;
    max-width: 1200px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: none;
        width: 315px;
        row-gap: 30px;
    }
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 378px;

    grid-row: 1/3;
    grid-column: 2/3;

    @media screen and (max-width: 768px) {
        width: 315px;
        grid-row: auto;
        grid-column: auto;
    }
`;

const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 32px 64px;
    gap: ${({ gap }) => (gap ? gap : "16px")};

    background: #f2f2f2;
    border-radius: 16px;

    box-sizing: border-box;
    width: 378px;
    ${({ auto }) => (auto ? "height: auto" : "flex-grow: 1")};

    @media screen and (max-width: 768px) {
        width: 315px;
        padding: 32px;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    align-self: stretch;
`;

const Title = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: ${({ language }) => (language === "en" ? "48px" : "70px")};
    line-height: 84px;
    display: flex;
    align-items: center;

    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    align-self: stretch;
    flex-grow: 1;

    order: 0;
`;

const FindOutMore = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    display: flex;
    align-items: flex-end;

    color: #000000;
    align-self: stretch;
`;

const Button = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;
    align-self: stretch;

    background: #92b4ec;
    border-radius: 16px;

    border: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    text-decoration: none;

    color: #ffffff;
    cursor: pointer;
    &:active {
        background: transparent;
        padding: 5px 16px;
        border: 3px solid #92b4ec;
        color: #92b4ec;
    }
`;

const Type = styled.h4`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
`;

const PriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

const Price = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 76px;

    color: #000000;
`;

const EachYear = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #000000;
`;

const SubTitle = styled.h5`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
`;

const Benifits = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

const Benifit = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

const Icon = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
    color: #000000;
`;

// const Note = styled.p`
//     font-family: "Pretendard Variable";
//     font-style: normal;
//     font-weight: 200;
//     font-size: 12px;
//     line-height: 14px;

//     color: #000000;
//     align-self: stretch;
// `;

function MembershipPage() {
    const { t } = useTranslation("Membership");
    return (
        <StyledMain>
            <Container>
                {/* <BoxContainer> */}
                <Title language={i18n.language}>{t("title")}</Title>
                {/* </BoxContainer> */}
                {/* <BoxContainer>
                    <BoxContent>
                        <Content>
                            <Type>{t("type.basic.title")}</Type>
                            <PriceContainer>
                                <Price>{t("type.basic.price")}</Price>
                            </PriceContainer>
                            <SubTitle>{t("type.basic.subtitle")}</SubTitle>
                            <Benifits>
                                <Benifit>
                                    <Icon icon={faCheck} size="2x" />
                                    {t("type.basic.benifits.1")}
                                </Benifit>
                                <Benifit>
                                    <Icon icon={faCheck} size="2x" />
                                    {t("type.basic.benifits.2")}
                                </Benifit>
                                <Benifit>
                                    <Icon icon={faCheck} size="2x" />
                                    {t("type.basic.benifits.3")}
                                </Benifit>
                            </Benifits>
                        </Content>
                        <Button href="https://studentsunionucl.org/clubs-societies/korean-society">
                            {t("join")}
                        </Button>
                    </BoxContent>
                </BoxContainer> */}
                <BoxContainer>
                    <BoxContent>
                        <Content>
                            <Type>{t("type.standard.title")}</Type>
                            <PriceContainer>
                                <Price>{t("type.standard.price")}</Price>
                                <EachYear>
                                    {t("type.standard.each-year")}
                                </EachYear>
                            </PriceContainer>
                            <SubTitle>{t("type.standard.subtitle")}</SubTitle>
                            <Benifits>
                                <Benifit>
                                    <Icon icon={faCheck} size="2x" />
                                    {t("type.standard.benifits.1")}
                                </Benifit>
                                <Benifit>
                                    <Icon icon={faCheck} size="2x" />
                                    {t("type.standard.benifits.2")}
                                </Benifit>
                                <Benifit>
                                    <Icon icon={faCheck} size="2x" />
                                    {t("type.standard.benifits.3")}
                                </Benifit>
                            </Benifits>
                            {/* <Note>{t("type.standard.note")}</Note> */}
                        </Content>
                        <Button href="https://studentsunionucl.org/clubs-societies/korean-society">
                            {t("join")}
                        </Button>
                    </BoxContent>
                </BoxContainer>
                <BoxContent auto gap="30px">
                    <FindOutMore>{t("find-out-more")}</FindOutMore>
                    <Button as={Link} to={"/faq"}>{t("ask")}</Button>
                </BoxContent>
            </Container>
        </StyledMain>
    );
}

export default MembershipPage;
