import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonLink } from "../components/Styles/Button.styled";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;

    @media screen and (max-width: 768px) {
        margin: 30px;
        padding: 0;
        gap: 30px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

const TitleContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 128px;
    line-height: 153px;

    color: #000000;
`;

const SubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
`;

// export const ButtonLink = styled(Link)`
//     box-sizing: border-box;
//     padding: 16px 64px;

//     background: #92b4ec;
//     border-radius: 16px;

//     border: none;

//     font-family: "Pretendard Variable";
//     font-style: normal;
//     font-weight: 700;
//     font-size: 20px;
//     line-height: 24px;

//     text-decoration: none;

//     color: #ffffff;
//     cursor: pointer;

//     &:active {
//         background: transparent;
//         padding: 13px 61px;
//         border: 3px solid #92b4ec;
//         color: #92b4ec;
//     }

//     &:disabled {
//         background: #b9c0c8;
//         cursor: not-allowed;
//     }
// `;

function NotFound() {
    const { t } = useTranslation("NotFound");
    return (
        <StyledMain>
            <MainContainer>
                <TitleContent>
                    <Title>404</Title>
                    <SubTitle>{t("not-found")}</SubTitle>
                </TitleContent>

                <ButtonLink to="/" width={"300px"}>{t("back")}</ButtonLink>
            </MainContainer>
        </StyledMain>
    );
}

export default NotFound;
