import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 300px;
    height: ${({ height }) => (height ? height : "auto")};
    align-self: stretch;

    position: relative;

    @media screen and (max-width: 768px) {
        width: auto;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    height: ${({ height }) => (height ? height : "auto")};

    align-self: stretch;
    position: relative;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
`;

const StyledLabel = styled.label`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
    color: #000000;

    span {
        color: #606060;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    position: absolute;
    width: 16px;
    height: 16px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ confirm }) => (confirm ? "#37A693" : "#606060")};

    &:hover {
        cursor: pointer;
    }
`;

const Field = styled.input`
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    padding: 0px 16px;
    height: 35px;

    width: 300px;

    border: 3px solid
        ${({ confirm, error }) =>
            confirm ? "#37A693" : error ? "#FF7171" : "#606060"};
    background: ${({ confirm, error }) =>
        confirm
            ? "rgba(55, 166, 147, 0.1)"
            : error
            ? "rgba(255, 113, 113, 0.1)"
            : "transparent"};
    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    color: #000000;

    &::placeholder {
        color: #606060;
    }

    &:focus {
        background: rgba(146, 180, 236, 0.1);
        border: 3px solid #92b4ec;
        outline: none;
    }

    @media screen and (max-width: 768px) {
        width: 315px;
    }
`;

const BoxField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;

    align-self: stretch;

    box-sizing: border-box;
    padding: 0px 16px;
    height: 35px;

    border: 3px solid ${({ confirm }) => (confirm ? "#37A693" : "#FF7171")};
    background: ${({ confirm }) =>
        confirm ? "rgba(55, 166, 147, 0.1)" : "rgba(255, 113, 113, 0.1)"};
    border-radius: 16px;
`;

const BoxFieldText = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

const BoxFieldIcon = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
    color: ${({ confirm }) => (confirm ? "#37A693" : "#FF7171")};
`;

const Message = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 17px;

    color: #ff7171;
`;

const Wrap = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    position: relative;
    color: #606060;
`;

const Find = styled(Link)`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    text-align: right;
    text-decoration: none;

    color: #000000;

    padding-top: 6px;

    align-self: stretch;
`;

const Conditions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Condition = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 17px;

    color: ${({ confirm }) => (confirm ? "#37a693" : "#FF7171")};

    display: flex;
    align-items: center;
    gap: 2px;
`;

const CheckIcon = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
    color: inherit;
`;

const Button = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;
    align-self: stretch;

    background: #92b4ec;
    border-radius: 16px;

    border: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #ffffff;
    cursor: pointer;
    &:enabled {
        &:active {
            background: transparent;
            padding: 5px 16px;
            border: 3px solid #92b4ec;
            color: #92b4ec;
        }
    }

    &:disabled {
        background: #b9c0c8;
        cursor: not-allowed;
    }
`;

const Radio = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
    position: relative;

    box-sizing: border-box;

    padding: 1px 1px;

    border: 3px solid #606060;
    border-radius: 16px;

    &.focus {
        background: rgba(146, 180, 236, 0.1);
        border: 3px solid #92b4ec;
        outline: none;

        input {
            &:checked + label {
                background: #92b4ec;
            }
        }
    }
`;

const RadioLabel = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;

    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

const RadioInput = styled.input`
    &:checked + ${RadioLabel} {
        background: #606060;
        border-radius: 16px;

        color: #ffffff;
    }
`;

const Input = {
    Container,
    Content,
    LabelContainer,
    StyledLabel,
    Field,
    BoxField,
    BoxFieldText,
    BoxFieldIcon,
    Message,
    Wrap,
    Icon,
    Find,
    Conditions,
    Condition,
    CheckIcon,
    Button,
    Radio,
    RadioInput,
    RadioLabel,
};

export default Input;
