import {
    faFacebookSquare,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faSquareEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import design_pc from "../assets/images/introduce_design_pc.svg";
import design_mobile from "../assets/images/introduce_design_mobile.svg";
import { ButtonLink } from "../components/Styles/Button.styled";

const StyledMain = styled.main`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
`;

const BannerSection = styled.section`
    width: 100%;
    height: 687px;
    position: relative;

    display: grid;
    grid-template-columns: 685px 480px;
    grid-template-rows: 100px 1fr;
    justify-content: center;
    align-items: start;
    gap: 30px;

    box-sizing: border-box;
    padding-top: 64px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 315px;
        grid-template-rows: none;
        height: 926px;
        gap: 15px;
        align-content: start;
        padding-top: 30px;
    }
`;

const BannerImg = styled.div`
    background-image: url(${design_pc});
    background-repeat: repeat-x;
    background-position-x: center;
    background-size: 1280px 687px;
    position: absolute;

    width: 100%;
    height: 687px;
    z-index: -99;

    @media screen and (max-width: 768px) {
        background-image: url(${design_mobile});
        background-size: 576px 926px;
        height: 926px;
        top: 0px;
    }
`;

const DescriptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    order: 2;
`;

const Description = styled.p`
    width: 685px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #ffffff;

    strong {
        font-weight: 700;
    }

    @media screen and (max-width: 768px) {
        width: 315px;
    }
`;

const Writer = styled.h4`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;

    color: #ffffff;

    strong {
        font-weight: 700;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 16px;

    background: #606060;
    border-radius: 16px;

    order: 0;
`;

const Title = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 84px;

    color: #ffffff;
    text-align: center;

    .introduce {
        color: #606060;
        text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff,
            0 -1px #ffffff;
    }

    @media screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 48px;
        .introduce {
            display: block;
        }
    }
`;

const Image = styled.img`
    width: 480px;
    height: 367px;
    object-fit: cover;
    border-radius: 16px;
    z-index: 1;
    order: 1;

    @media screen and (max-width: 768px) {
        width: 315px;
        height: 240px;
    }
`;

const InfoSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;

    margin: 64px 0;
    z-index: 1;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin: 30px 0;
    }
`;

const InfoCard = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => (direction ? direction : "column")};
    align-items: flex-start;
    box-sizing: border-box;
    padding: 64px;
    gap: ${({ gap }) => (gap ? gap : "30px")};

    width: 582px;

    background: #f2f2f2;
    border-radius: 32px;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        width: 315px;
        padding: 32px;
        gap: 15px;
    }
`;

const InfoContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 30px;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 768px) {
        gap: 15px;
    }
`;

const InfoTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

const InfoContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    align-self: stretch;
`;

const InfoSubTitle = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
    }
`;

const Icons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;

const IconLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
    width: 32px;
    height: 32px;

    color: #000000;
`;

const Anchor = styled.a`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;

    text-decoration: none;

    transition: 0.2s;

    align-self: left;

    &:hover {
        color: #606060;
    }

    @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 17px;
    }
`;

const StyledFrame = styled.iframe`
    border-radius: 16px;
`;

function IntroducePage() {
    const { t, i18n } = useTranslation("IntroducePage");
    return (
        <StyledMain>
            <BannerSection>
                <BannerImg />
                <TitleContainer>
                    {i18n.language === "ko" ? (
                        <Title>
                            <span>UCLKS</span>
                            <span className="introduce">를 소개합니다!</span>
                        </Title>
                    ) : (
                        <Title>
                            <span className="introduce">We are</span>{" "}
                            <span>UCLKS!</span>
                        </Title>
                    )}
                </TitleContainer>
                <DescriptionWrap>
                    {i18n.language === "ko" ? (
                        <Description>
                            안녕하세요,{" "}
                            <strong>UCL Korean Society(UCLKS)</strong>에 오신걸
                            환영합니다.
                            <br />
                            <br />
                            UCLKS는 UCL 한인분들을 위한 society로서, 런던 내
                            최대규모를 자랑하는 한인학생회 입니다.
                            <br />
                            <br />
                            UCLKS는 학우분들의 친목과 교류, 한국인이라는
                            정체성을 잃지 않게 하기 위해 존재합니다. 이를 위해
                            다양한 사교 이벤트와 아카데믹 이벤트들을 꾸준히
                            진행하고 있습니다. 뿐만 아니라 한인 학우분들의
                            원만한 런던 정착과 네트워킹 형성을 위해 힘쓰고
                            있습니다.
                            <br />
                            <br />
                            더불어, <strong>런던 총한인학생회(OKSE)</strong>
                            의 일원으로서 타 대학 학우분들과의 소통과 교류의
                            기회를 제공해 드리고 있습니다.
                            <br />
                            <br />
                            앞으로도 더욱 다양한 이벤트들로 찾아뵐 예정이니 많은
                            관심 부탁드립니다. 감사합니다.
                        </Description>
                    ) : (
                        <Description>
                            Hello, Welcome to{" "}
                            <strong>UCL Korean Society(UCLKS)</strong>.
                            <br />
                            <br />
                            UCLKS is the largest Korean student society for
                            Koreans in UCL
                            <br />
                            <br />
                            UCLKS is about friendship and exchange of Korean
                            students, It exists so that you don't lose your
                            identity as Korean. We are continuing to carry out
                            various events for socialising and academics.
                            Moreover, we are working hard for Korean students to
                            settle in London smoothly and form networking with
                            others.
                            <br />
                            <br />
                            As a member of the{" "}
                            <strong>
                                Organisation of Korean Societies in
                                England(OKSE)
                            </strong>
                            , UCLKS provide opportunities for exchange and
                            communication with other Korean communities
                            belonging to other University
                            <br />
                            <br />
                            We are looking forward to seeing you at much more
                            various events in the future, so look forward to it.
                            Thank you.
                        </Description>
                    )}

                    <Writer>
                        2022/23
                        <br />
                        <strong>{t("president")}</strong>
                    </Writer>
                </DescriptionWrap>
                <Image src="/committes/main.jpg" alt="main" />
            </BannerSection>
            <InfoSection>
                <InfoCard>
                    <InfoTitle>{t("ask.title")}</InfoTitle>
                    <InfoContent>
                        <InfoSubTitle>{t("ask.contact")}</InfoSubTitle>
                        <Icons>
                            <IconLink href="mailto:uclks.committee@gmail.com">
                                <Icon icon={faSquareEnvelope} size="2xl" />
                            </IconLink>
                            <IconLink href="https://www.facebook.com/groups/uclks">
                                <Icon icon={faFacebookSquare} size="2xl" />
                            </IconLink>
                            <IconLink href="https://instagram.com/uclksoc">
                                <Icon icon={faInstagram} size="2xl" />
                            </IconLink>
                        </Icons>
                    </InfoContent>

                    <InfoContent>
                        <InfoSubTitle>{t("ask.question")}</InfoSubTitle>
                        <ButtonLink to="/faq" width="300px">{t("ask.faq")}</ButtonLink>
                    </InfoContent>
                </InfoCard>

                <InfoCard direction="row" gap="15px">
                    <InfoContentWrap>
                        <InfoTitle>{t("location.title")}</InfoTitle>
                        <InfoContent>
                            <InfoSubTitle>{t("location.address")}</InfoSubTitle>
                            <Anchor href="https://goo.gl/maps/3TkvFATx4XkU3uXP6">
                                University College London,
                                <br />
                                Gower St, London,
                                <br />
                                WC1E 6BT
                            </Anchor>
                        </InfoContent>
                    </InfoContentWrap>
                    <InfoContentWrap>
                        <StyledFrame
                            src={`https://www.google.com/maps/embed?pb=${
                                i18n.language === "ko"
                                    ? "!1m14!1m8!1m3!1d2482.381664116404!2d-0.1362288!3d51.5245592!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b2f69173579%3A0xd008c67faecc133e!2z7Jyg64uI67KE7Iuc7YuwIOy5vOumrOyngCDrn7DrjZg!5e0!3m2!1sko!2suk!4v1660230780374!5m2!1sko!2suk"
                                    : "!1m18!1m12!1m3!1d2482.381665226461!2d-0.1362287837455545!3d51.52455917963792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b2f69173579%3A0xd008c67faecc133e!2sUniversity%20College%20London!5e0!3m2!1sen!2suk!4v1660230707807!5m2!1sen!2suk"
                            }`}
                            width="100%"
                            height="100%"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        />
                    </InfoContentWrap>
                </InfoCard>
            </InfoSection>
        </StyledMain>
    );
}

export default IntroducePage;
