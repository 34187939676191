import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonLink } from "../components/Styles/Button.styled";
import styled from "styled-components";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Input from "../components/Styles/Input.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko, enGB } from "date-fns/esm/locale";
import parseISO from "date-fns/parseISO";
import {
    changeEmail,
    getUserInfo,
    resendEmail,
    updateProfile,
} from "../actions/userActions";
import {
    USER_INFO_RESET,
    USER_UPDATE_PROFILE_RESET,
} from "../constants/userConstants";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 122px;
    gap: 60px;
    box-sizing: border-box;

    width: 972px;

    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        box-shadow: none;
        width: 315px;
        height: auto;
        padding: 0;
        gap: 30px;
        background: none;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
`;

const Forms = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 64px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 30px;
    }
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    box-sizing: border-box;

    border-right: ${(props) => (props.divider ? "1px solid #606060" : "none")};
    padding-right: ${(props) => (props.divider ? "64px" : "0px")};

    @media screen and (max-width: 768px) {
        border-right: none;
        padding-right: 0;
        border-bottom: ${(props) =>
            props.divider ? "1px solid #606060" : "none"};
        padding-bottom: ${(props) => (props.divider ? "30px" : "0px")};
    }
`;

const FormAccount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`;

const FormTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #000000;

    span {
        color: #606060;
    }
`;

const FormElement = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    align-self: stretch;
`;

const StyledDatePicker = styled(DatePicker)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    box-sizing: border-box;
    padding: 0px 16px;
    height: 35px;

    border: 3px solid
        ${({ confirm, error }) =>
            confirm ? "#37A693" : error ? "#FF7171" : "#606060"};
    background: ${({ confirm, error }) =>
        confirm
            ? "rgba(55, 166, 147, 0.1)"
            : error
            ? "rgba(255, 113, 113, 0.1)"
            : "transparent"};
    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    color: #000000;

    &::placeholder {
        color: #606060;
    }

    &:focus {
        background: rgba(146, 180, 236, 0.1);
        border: 3px solid #92b4ec;
        outline: none;
    }
`;

function ProfilePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation("ProfilePage");

    const userInfo = useSelector((state) => state.userInfo);
    const { user: info } = userInfo;

    const userProfile = useSelector((state) => state.userProfile);
    const { user: profile } = userProfile;

    const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
    const { success } = userUpdateProfile;

    const userLogin = useSelector((state) => state.userLogin);
    const { isLoggedIn } = userLogin;

    const [name, setName] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [gender, setGender] = useState(0);
    const [department, setDepartment] = useState("");

    const [genderFocus, setGenderFocus] = useState(false);
    const [degree, setDegree] = useState(0);
    const [degreeFocus, setDegreeFocus] = useState(false);

    const [isName, setIsName] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const [email, setEmail] = useState("");
    const [isEmail, setIsEmail] = useState(false);
    const [isEmailChanged, setIsEmailChanged] = useState(false);

    const handleName = (value) => {
        setName(value);
        if (value.length <= 0) {
            setIsName(false);
        } else {
            setIsName(true);
        }
    };

    const handleGender = (e) => {
        setGender(parseInt(e.target.value));
    };

    const handleDegree = (e) => {
        setDegree(parseInt(e.target.value));
    };

    const handleVerifyEmail = () => {
        dispatch(resendEmail());
        navigate("/verify-email");
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
        const reg =
            /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
        if (!reg.test(e.target.value)) {
            setIsEmail(false);
        } else {
            setIsEmail(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const year = birthday?.getFullYear();
        const month = (birthday?.getMonth() + 1).toString().padStart(2, "0");
        const date = birthday?.getDate();
        const birthdate =
            birthday === null ? null : year + "-" + month + "-" + date;
        dispatch(updateProfile(name, birthdate, gender, degree, department));
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        dispatch(changeEmail(email));
        navigate("/verify-email");
    };

    //Either getUserInfo or setDefaults as the database profile defaults
    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/");
        }
        if (profile) {
            handleName(profile.name);
            setBirthday(
                profile.birthday !== null ? parseISO(profile.birthday) : null
            );
            setGender(profile.gender);
            setDegree(profile.degree);
            setDepartment(
                profile.department !== null ? profile.department : ""
            );
        }
        if (info) {
            setEmail(info.email);
        }
    }, [isLoggedIn, profile, info, navigate]);

    //Either getUserInfo or setDefaultEmail as the database email
    useEffect(() => {
        dispatch(getUserInfo());
        return () => {
            dispatch({ type: USER_INFO_RESET });
            dispatch({ type: USER_UPDATE_PROFILE_RESET });
        };
    }, [dispatch]);

    //Onli activated when name, birtday, gender, degree, department or database profile data is changed
    useEffect(() => {
        if (profile) {
            if (
                name !== profile.name  ||
                (birthday === null ? null : birthday.getTime()) !==
                    (profile.birthday === null
                        ? null
                        : parseISO(profile.birthday).getTime()) ||
                gender !== profile.gender ||
                degree !== profile.degree ||
                department !== (profile.department ?? "")
            ) {
                console.log(true);
                setIsChanged(true);
            } else {
                console.log(false);
                setIsChanged(false);
            }
        }
    }, [name, birthday, gender, degree, department, profile]);

    //Only activated when email or database email stored is changed
    useEffect(() => {
        if (info) {
            if (email !== info.email) {
                setIsEmailChanged(true);
            } else {
                setIsEmailChanged(false);
            }
        }
    }, [email, info]);

    return (
        <StyledMain>
            <Container>
                <StyledTitle>{t("title")}</StyledTitle>
                <Forms>
                    <Form action="" onSubmit={handleSubmit} divider>
                        <FormTitle>
                            {t("general.title")}
                            {success && <span> - {t("general.changed")}</span>}
                        </FormTitle>
                        <Input.Container>
                            <Input.Content>
                                <Input.StyledLabel htmlFor="name">
                                    {t("general.name")}
                                </Input.StyledLabel>
                                <Input.Wrap>
                                    <Input.Field
                                        type="text"
                                        id="name"
                                        placeholder={t("general.example-name")}
                                        value={name}
                                        confirm={isName ? 1 : 0}
                                        onChange={(e) =>
                                            handleName(e.target.value)
                                        }
                                    />
                                    {isName && (
                                        <Input.Icon
                                            icon={faCheck}
                                            size="2x"
                                            confirm={1}
                                        />
                                    )}
                                </Input.Wrap>
                            </Input.Content>
                        </Input.Container>

                        <Input.Container>
                            <Input.Content>
                                <Input.LabelContainer>
                                    <Input.StyledLabel htmlFor="birthday">
                                        {t("general.birthday")}
                                        <span> ({t("general.optional")})</span>
                                    </Input.StyledLabel>
                                </Input.LabelContainer>
                                <Input.Wrap>
                                    <StyledDatePicker
                                        selected={birthday}
                                        onChange={(date) => setBirthday(date)}
                                        placeholderText={t(
                                            "general.example-date"
                                        )}
                                        locale={
                                            i18n.language === "ko" ? ko : enGB
                                        }
                                        dateFormat={
                                            i18n.language === "ko"
                                                ? "yyyy - MM - dd"
                                                : "dd - MM - yyyy"
                                        }
                                        maxDate={new Date()}
                                        id="birthday"
                                    />
                                </Input.Wrap>
                            </Input.Content>
                        </Input.Container>

                        <Input.Container>
                            <Input.Content>
                                <Input.LabelContainer>
                                    <Input.StyledLabel
                                        htmlFor={
                                            gender === 0
                                                ? "undefined"
                                                : gender === 1
                                                ? "male"
                                                : "female"
                                        }
                                    >
                                        {t("general.gender.label")}
                                        <span> ({t("general.optional")})</span>
                                    </Input.StyledLabel>
                                </Input.LabelContainer>
                                <Input.Wrap>
                                    <Input.Radio
                                        className={genderFocus ? "focus" : ""}
                                    >
                                        <Input.RadioInput
                                            type="radio"
                                            id="undefined"
                                            name="gender"
                                            value="0"
                                            className="sr-only"
                                            checked={gender === 0}
                                            onFocus={(e) =>
                                                setGenderFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setGenderFocus(false)
                                            }
                                            onChange={handleGender}
                                        />
                                        <Input.RadioLabel htmlFor="undefined">
                                            {t(
                                                "general.gender.options.undefined"
                                            )}
                                        </Input.RadioLabel>

                                        <Input.RadioInput
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            value="1"
                                            className="sr-only"
                                            checked={gender === 1}
                                            onFocus={(e) =>
                                                setGenderFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setGenderFocus(false)
                                            }
                                            onChange={handleGender}
                                        />
                                        <Input.RadioLabel htmlFor="male">
                                            {t("general.gender.options.male")}
                                        </Input.RadioLabel>
                                        <Input.RadioInput
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value="2"
                                            className="sr-only"
                                            checked={gender === 2}
                                            onFocus={(e) =>
                                                setGenderFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setGenderFocus(false)
                                            }
                                            onChange={handleGender}
                                        />
                                        <Input.RadioLabel htmlFor="female">
                                            {t("general.gender.options.female")}
                                        </Input.RadioLabel>
                                    </Input.Radio>
                                </Input.Wrap>
                            </Input.Content>
                        </Input.Container>

                        <Input.Container>
                            <Input.Content>
                                <Input.LabelContainer>
                                    <Input.StyledLabel
                                        htmlFor={
                                            degree === 0
                                                ? "none"
                                                : degree === 1
                                                ? "undergraduate"
                                                : degree === 2
                                                ? "postgraduate"
                                                : "alumni"
                                        }
                                    >
                                        {t("general.degree.label")}
                                        <span> ({t("general.optional")})</span>
                                    </Input.StyledLabel>
                                </Input.LabelContainer>
                                <Input.Wrap>
                                    <Input.Radio
                                        className={degreeFocus ? "focus" : ""}
                                    >
                                        <Input.RadioInput
                                            type="radio"
                                            id="none"
                                            name="degree"
                                            value="0"
                                            className="sr-only"
                                            checked={degree === 0}
                                            onFocus={(e) =>
                                                setDegreeFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setDegreeFocus(false)
                                            }
                                            onChange={handleDegree}
                                        />
                                        <Input.RadioLabel htmlFor="none">
                                            {t("general.degree.options.none")}
                                        </Input.RadioLabel>

                                        <Input.RadioInput
                                            type="radio"
                                            id="undergraduate"
                                            name="degree"
                                            value="1"
                                            className="sr-only"
                                            checked={degree === 1}
                                            onFocus={(e) =>
                                                setDegreeFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setDegreeFocus(false)
                                            }
                                            onChange={handleDegree}
                                        />
                                        <Input.RadioLabel htmlFor="undergraduate">
                                            {t(
                                                "general.degree.options.undergraduate"
                                            )}
                                        </Input.RadioLabel>
                                        <Input.RadioInput
                                            type="radio"
                                            id="postgraduate"
                                            name="degree"
                                            value="2"
                                            className="sr-only"
                                            checked={degree === 2}
                                            onFocus={(e) =>
                                                setDegreeFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setDegreeFocus(false)
                                            }
                                            onChange={handleDegree}
                                        />
                                        <Input.RadioLabel htmlFor="postgraduate">
                                            {t(
                                                "general.degree.options.postgraduate"
                                            )}
                                        </Input.RadioLabel>
                                        <Input.RadioInput
                                            type="radio"
                                            id="alumni"
                                            name="degree"
                                            value="3"
                                            className="sr-only"
                                            checked={degree === 3}
                                            onFocus={(e) =>
                                                setDegreeFocus(true)
                                            }
                                            onBlur={(e) =>
                                                setDegreeFocus(false)
                                            }
                                            onChange={handleDegree}
                                        />
                                        <Input.RadioLabel htmlFor="alumni">
                                            {t("general.degree.options.alumni")}
                                        </Input.RadioLabel>
                                    </Input.Radio>
                                </Input.Wrap>
                            </Input.Content>
                        </Input.Container>

                        <Input.Container>
                            <Input.Content>
                                <Input.LabelContainer>
                                    <Input.StyledLabel htmlFor="department">
                                        {t("general.department")}
                                        <span> ({t("general.optional")})</span>
                                    </Input.StyledLabel>
                                </Input.LabelContainer>

                                <Input.Wrap>
                                    <Input.Field
                                        type="text"
                                        id="department"
                                        placeholder="BSc Mathematics"
                                        value={department}
                                        onChange={(e) =>
                                            setDepartment(e.target.value)
                                        }
                                    />
                                </Input.Wrap>
                            </Input.Content>
                        </Input.Container>

                        <Input.Container>
                            <Button
                                type="submit"
                                disabled={isChanged && isName ? 0 : 1}
                            >
                                {t("general.save")}
                            </Button>
                        </Input.Container>
                    </Form>

                    <FormAccount>
                        <FormTitle>{t("account.title")}</FormTitle>
                        <Form action="" onSubmit={handleEmailSubmit}>
                            <Input.Container>
                                <Input.Content>
                                    <Input.StyledLabel htmlFor="email">
                                        {t("account.email")}
                                    </Input.StyledLabel>
                                    <Input.Wrap>
                                        <Input.Field
                                            type="text"
                                            id="email"
                                            placeholder="username@email.com"
                                            value={email}
                                            confirm={
                                                info &&
                                                info.email_verified_at &&
                                                !isEmailChanged
                                                    ? 1
                                                    : 0
                                            }
                                            onChange={handleEmail}
                                        />
                                        {info &&
                                            info.email_verified_at &&
                                            !isEmailChanged && (
                                                <Input.Icon
                                                    icon={faCheck}
                                                    size="2x"
                                                    confirm={1}
                                                />
                                            )}
                                    </Input.Wrap>
                                </Input.Content>
                            </Input.Container>

                            <Input.Container>
                                {info?.email_verified_at ? (
                                    isEmailChanged && isEmail ? (
                                        <Button type="submit" disabled={0}>
                                            {t("account.change-email")}
                                        </Button>
                                    ) : (
                                        <Button type="submit" disabled={1}>
                                            {t("account.change-email")}
                                        </Button>
                                    )
                                ) : isEmailChanged ? (
                                    <Button
                                        type="submit"
                                        disabled={isEmail ? 0 : 1}
                                    >
                                        {t("account.change-email")}
                                    </Button>
                                ) : (
                                    <Button onClick={handleVerifyEmail}>
                                        {t("account.verify-email")}
                                    </Button>
                                )}
                            </Input.Container>
                        </Form>

                        <FormElement>
                            <Input.Container>
                                <Input.Content>
                                    <Input.StyledLabel htmlFor="password">
                                        {t("account.password")}
                                    </Input.StyledLabel>
                                    <Input.Wrap>
                                        <Input.BoxField
                                            id="password"
                                            confirm={1}
                                        >
                                            <Input.BoxFieldText>
                                                {t("account.password-hidden")}
                                            </Input.BoxFieldText>
                                            <Input.BoxFieldIcon
                                                icon={faCheck}
                                                size="2x"
                                                confirm={1}
                                            />
                                        </Input.BoxField>
                                    </Input.Wrap>
                                </Input.Content>
                            </Input.Container>
                            <ButtonLink to="/change-password">
                                {t("account.change-password")}
                            </ButtonLink>
                        </FormElement>
                    </FormAccount>
                </Forms>
            </Container>
        </StyledMain>
    );
}

export default ProfilePage;
