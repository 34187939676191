import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../components/Styles/Button.styled";
import {
    faEye,
    faEyeSlash,
    faCheck,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../components/Styles/Input.styled";
import MoveTo from "../components/Styles/MoveTo.styled";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../actions/userActions";
import { USER_REGISTER_RESET } from "../constants/userConstants";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 32px 122px;

    width: 582px;
    height: 742px;

    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        box-shadow: none;
        width: 315px;
        height: auto;
        padding: 0;
        gap: 30px;
        background: none;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

const ReadThisFirst = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
`;

const StyledLink = styled(Link)`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;

    text-decoration: none;
    color: #92b4ec;
`;

function RegisterPage() {
    const dispatch = useDispatch();
    const userRegister = useSelector((state) => state.userRegister);
    const { success, error } = userRegister;
    const userProfile = useSelector((state) => state.userProfile);
    const { user: profile, loading } = userProfile;

    const [t] = useTranslation("Register");
    const navigate = useNavigate();

    const [isName, setIsName] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [inRange, setInRange] = useState(false);
    const [containLowerUpper, setContainLowerUpper] = useState(false);
    const [containNumber, setContainNumber] = useState(false);
    const [containSpecial, setContainSpecial] = useState(false);
    const [allTrue, setAllTrue] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");

    const [disabled, setDisabled] = useState(true);
    const [show, setShow] = useState(false);

    const successRef = useRef();

    const handleVisibility = () => {
        setShow(!show);
    };

    const handleName = (e) => {
        setName(e.target.value);
        if (e.target.value.length <= 0) {
            setIsName(false);
        } else {
            setIsName(true);
        }
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
        const reg =
            /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
        if (!reg.test(e.target.value)) {
            setIsEmail(false);
        } else {
            setIsEmail(true);
        }
    };

    const reCheckPassword = useCallback(() => {
        if (password === "" || rePassword === "" || password !== rePassword) {
            setConfirmPassword(false);
        } else {
            setConfirmPassword(true);
        }
    }, [password, rePassword]);

    const checkPassword = (e) => {
        const value = e.target.value;
        let range = false;
        let lowerUpper = false;
        let number = false;
        let special = false;
        setPassword(value);
        if (value.length < 8 || value.length > 64) {
            range = false;
        } else {
            range = true;
        }
        if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            lowerUpper = false;
        } else {
            lowerUpper = true;
        }
        if (!/[0-9]/.test(value)) {
            number = false;
        } else {
            number = true;
        }
        if (!/[!()-.?[\]_`~;:@#$%^&*+=]/.test(value)) {
            special = false;
        } else {
            special = true;
        }

        setInRange(range);
        setContainLowerUpper(lowerUpper);
        setContainNumber(number);
        setContainSpecial(special);

        if (!range || !lowerUpper || !number || !special) {
            setAllTrue(false);
        } else {
            setAllTrue(true);
        }
    };

    const checkForm = useCallback(() => {
        if (!isName || !isEmail || !allTrue || !confirmPassword) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [isName, isEmail, allTrue, confirmPassword]);

    const handleRegister = (e) => {
        e.preventDefault();
        dispatch(register(name, email, password, rePassword));
    };

    useEffect(() => {
        reCheckPassword();
        checkForm();
    }, [name, email, password, rePassword, reCheckPassword, checkForm]);

    useEffect(() => {
        successRef.current = success;
        if (success) {
            navigate("/verify-email");
        } else {
            if (loading === false && profile?.user_id !== undefined) {
                navigate("/");
            }
        }
    }, [loading, success, profile, navigate]);

    useEffect(() => {
        return () => {
            if (successRef.current === undefined) {
                dispatch({ type: USER_REGISTER_RESET });
            }
        };
    }, [dispatch]);
    return (
        <StyledMain>
            <Container>
                <StyledTitle>{t("title")}</StyledTitle>
                <StyledForm action="">
                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="name">
                                {t("name")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="text"
                                    id="name"
                                    placeholder={t("example-name")}
                                    confirm={isName ? 1 : 0}
                                    onChange={handleName}
                                />
                                {isName && (
                                    <Input.Icon
                                        icon={faCheck}
                                        size="2x"
                                        confirm={1}
                                    />
                                )}
                            </Input.Wrap>
                            {error && error.name && (
                                <Input.Message>
                                    {t(`error.${error.name}`)}
                                </Input.Message>
                            )}
                        </Input.Content>
                    </Input.Container>

                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="email">
                                {t("email")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="email"
                                    id="email"
                                    placeholder="username@email.com"
                                    confirm={isEmail ? 1 : 0}
                                    error={error && error.email ? 1 : 0}
                                    onChange={handleEmail}
                                    value={email}
                                    autoComplete="on"
                                />
                                {isEmail && (
                                    <Input.Icon
                                        icon={faCheck}
                                        size="2x"
                                        confirm={1}
                                    />
                                )}
                            </Input.Wrap>
                            {error && error.email && (
                                <Input.Message>
                                    {t(`error.${error.email}`)}
                                </Input.Message>
                            )}
                        </Input.Content>
                    </Input.Container>

                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="password">
                                {t("password")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type={show ? "text" : "password"}
                                    id="password"
                                    autoComplete="on"
                                    placeholder="Password"
                                    onChange={checkPassword}
                                    confirm={allTrue ? 1 : 0}
                                    error={error && error.password ? 1 : 0}
                                />
                                <Input.Icon
                                    icon={show ? faEye : faEyeSlash}
                                    onClick={handleVisibility}
                                    size="2x"
                                />
                            </Input.Wrap>
                            {error && error.password && (
                                <Input.Message>
                                    {t(`error.${error.password}`)}
                                </Input.Message>
                            )}
                            <Input.Conditions>
                                <Input.Condition confirm={inRange ? 1 : 0}>
                                    <Input.CheckIcon
                                        icon={inRange ? faCheck : faXmark}
                                    />
                                    {t("validation.length")}
                                </Input.Condition>
                                <Input.Condition
                                    confirm={containLowerUpper ? 1 : 0}
                                >
                                    <Input.CheckIcon
                                        icon={
                                            containLowerUpper
                                                ? faCheck
                                                : faXmark
                                        }
                                    />
                                    {t("validation.lowerUpper")}
                                </Input.Condition>
                                <Input.Condition
                                    confirm={containNumber ? 1 : 0}
                                >
                                    <Input.CheckIcon
                                        icon={containNumber ? faCheck : faXmark}
                                    />
                                    {t("validation.number")}
                                </Input.Condition>
                                <Input.Condition
                                    confirm={containSpecial ? 1 : 0}
                                >
                                    <Input.CheckIcon
                                        icon={
                                            containSpecial ? faCheck : faXmark
                                        }
                                    />
                                    {t("validation.special")}
                                </Input.Condition>
                            </Input.Conditions>
                        </Input.Content>
                    </Input.Container>

                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="password-confirmation">
                                {t("confirm-password")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="password"
                                    id="password-confirmation"
                                    autoComplete="off"
                                    placeholder="Password"
                                    onChange={(e) =>
                                        setRePassword(e.target.value)
                                    }
                                    confirm={confirmPassword ? 1 : 0}
                                    error={error && error.confirmation ? 1 : 0}
                                />
                                {confirmPassword && (
                                    <Input.Icon
                                        icon={faCheck}
                                        size="2x"
                                        confirm={1}
                                    />
                                )}
                            </Input.Wrap>
                            {error && error.confirmation && (
                                <Input.Message>
                                    {t(`error.${error.confirmation}`)}
                                </Input.Message>
                            )}
                        </Input.Content>
                    </Input.Container>

                    <Input.Container>
                        {i18n.language === "ko" && (
                            <ReadThisFirst>
                                회원가입시{" "}
                                <StyledLink to="/terms">이용약관</StyledLink>과{" "}
                                <StyledLink to="/privacy">
                                    개인정보 처리방침
                                </StyledLink>
                                에 동의하게 됩니다.
                            </ReadThisFirst>
                        )}
                        {i18n.language === "en" && (
                            <ReadThisFirst>
                                By registering, you are agreeing to the{" "}
                                <StyledLink to="/terms">
                                    Terms & Conditions
                                </StyledLink>{" "}
                                and{" "}
                                <StyledLink to="/privacy">
                                    Privacy Policy
                                </StyledLink>
                                .
                            </ReadThisFirst>
                        )}
                    </Input.Container>

                    <Input.Container>
                        <Button
                            type="submit"
                            disabled={disabled ? 1 : 0}
                            onClick={handleRegister}
                        >
                            {t("register")}
                        </Button>
                    </Input.Container>
                </StyledForm>
                <MoveTo.Container>
                    <MoveTo.Message>{t("have-account")}</MoveTo.Message>
                    <MoveTo.StyledLink to="/login">
                        {t("login")}
                    </MoveTo.StyledLink>
                </MoveTo.Container>
            </Container>
        </StyledMain>
    );
}

export default RegisterPage;
