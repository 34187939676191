import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../components/Styles/Button.styled";
import {
    faEye,
    faEyeSlash,
    faCheck,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Input from "../components/Styles/Input.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../actions/userActions";
import { USER_CHANGE_PASSWORD_RESET } from "../constants/userConstants";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;

    @media screen and (max-width: 768px) {
        text-align: center;
    }
`;

const StyledNote = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 118px;

    max-width: 582px;

    gap: 30px;

    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 32px;

    @media screen and (max-width: 768px) {
        box-shadow: none;
        width: 315px;
        height: auto;
        padding: 0;
        background: none;
    }
`;

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

function ChangePasswordPage() {
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { isLoggedIn } = userLogin;
    const userInfo = useSelector((state) => state.userInfo);
    const { user } = userInfo;
    const userChangePassword = useSelector((state) => state.userChangePassword);
    const { error, success } = userChangePassword;

    const { t } = useTranslation("ChangePasswordPage");
    const navigate = useNavigate();

    const [isCurrentPassword, setIsCurrentPassword] = useState(false);

    const [inRange, setInRange] = useState(false);
    const [containLowerUpper, setContainLowerUpper] = useState(false);
    const [containNumber, setContainNumber] = useState(false);
    const [containSpecial, setContainSpecial] = useState(false);
    const [allTrue, setAllTrue] = useState(false);

    const [isConfirmPassword, setIsConfirmPassword] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [disabled, setDisabled] = useState(true);
    const [show, setShow] = useState(false);

    const handleVisibility = () => {
        setShow(!show);
    };

    const handleCurrentPassword = (e) => {
        setCurrentPassword(e.target.value);
        if (e.target.value.length <= 0) {
            setIsCurrentPassword(false);
        } else {
            setIsCurrentPassword(true);
        }
    };

    const checkConfirmPassword = useCallback(() => {
        if (
            newPassword === "" ||
            confirmPassword === "" ||
            newPassword !== confirmPassword
        ) {
            setIsConfirmPassword(false);
        } else {
            setIsConfirmPassword(true);
        }
    }, [newPassword, confirmPassword]);

    const checkPassword = (e) => {
        const value = e.target.value;
        let range = false;
        let lowerUpper = false;
        let number = false;
        let special = false;
        setNewPassword(value);
        if (value.length < 8 || value.length > 64) {
            range = false;
        } else {
            range = true;
        }
        if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
            lowerUpper = false;
        } else {
            lowerUpper = true;
        }
        if (!/[0-9]/.test(value)) {
            number = false;
        } else {
            number = true;
        }
        if (!/[!()-.?[\]_`~;:@#$%^&*+=]/.test(value)) {
            special = false;
        } else {
            special = true;
        }

        setInRange(range);
        setContainLowerUpper(lowerUpper);
        setContainNumber(number);
        setContainSpecial(special);

        if (!range || !lowerUpper || !number || !special) {
            setAllTrue(false);
        } else {
            setAllTrue(true);
        }
    };

    const checkForm = useCallback(() => {
        if (!isCurrentPassword || !allTrue || !isConfirmPassword) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [isCurrentPassword, allTrue, isConfirmPassword]);

    const handleChangePassword = (e) => {
        e.preventDefault();
        dispatch(changePassword(currentPassword, newPassword, confirmPassword));
    };

    useEffect(() => {
        if (!isLoggedIn || (user && !user.id)) {
            navigate("/");
        }

        if (success) {
            navigate("success");
        }

        checkConfirmPassword();
        checkForm();
    }, [
        currentPassword,
        newPassword,
        confirmPassword,
        isLoggedIn,
        user,
        success,
        checkConfirmPassword,
        checkForm,
        navigate,
    ]);

    useEffect(() => {
        return () => {
            dispatch({ type: USER_CHANGE_PASSWORD_RESET });
        };
    }, [dispatch]);

    return (
        <StyledMain>
            <Container>
                <StyledTitle>{t("title")}</StyledTitle>
                <StyledNote>{t("note")}</StyledNote>
                <StyledForm action="" onSubmit={handleChangePassword}>
                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="current-password">
                                {t("current-password")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="password"
                                    id="current-password"
                                    autoComplete="on"
                                    placeholder="Password"
                                    onChange={handleCurrentPassword}
                                    error={
                                        error &&
                                        (error["current-password"] ||
                                            error ===
                                                "current-password-not-match")
                                            ? 1
                                            : 0
                                    }
                                />
                            </Input.Wrap>
                            {error &&
                                (error["current-password"] ? (
                                    <Input.Message>
                                        {t(
                                            `error.${error["current-password"]}`
                                        )}
                                    </Input.Message>
                                ) : (
                                    error === "current-password-not-match" && (
                                        <Input.Message>
                                            {t(`error.${error}`)}
                                        </Input.Message>
                                    )
                                ))}
                        </Input.Content>
                    </Input.Container>
                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="new-password">
                                {t("new-password")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type={show ? "text" : "password"}
                                    id="new-password"
                                    autoComplete="on"
                                    placeholder="Password"
                                    onChange={checkPassword}
                                    confirm={allTrue ? 1 : 0}
                                    error={
                                        error && error["new-password"] ? 1 : 0
                                    }
                                />
                                <Input.Icon
                                    icon={show ? faEye : faEyeSlash}
                                    onClick={handleVisibility}
                                    size="2x"
                                />
                            </Input.Wrap>
                            {error && error["new-password"] ? (
                                <Input.Message>
                                    {t(`error.${error["new-password"]}`)}
                                </Input.Message>
                            ) : (
                                error === "same-password" && (
                                    <Input.Message>
                                        {t(`error.${error}`)}
                                    </Input.Message>
                                )
                            )}
                            <Input.Conditions>
                                <Input.Condition confirm={inRange ? 1 : 0}>
                                    <Input.CheckIcon
                                        icon={inRange ? faCheck : faXmark}
                                    />
                                    {t("validation.length")}
                                </Input.Condition>
                                <Input.Condition
                                    confirm={containLowerUpper ? 1 : 0}
                                >
                                    <Input.CheckIcon
                                        icon={
                                            containLowerUpper
                                                ? faCheck
                                                : faXmark
                                        }
                                    />
                                    {t("validation.lowerUpper")}
                                </Input.Condition>
                                <Input.Condition
                                    confirm={containNumber ? 1 : 0}
                                >
                                    <Input.CheckIcon
                                        icon={containNumber ? faCheck : faXmark}
                                    />
                                    {t("validation.number")}
                                </Input.Condition>
                                <Input.Condition
                                    confirm={containSpecial ? 1 : 0}
                                >
                                    <Input.CheckIcon
                                        icon={
                                            containSpecial ? faCheck : faXmark
                                        }
                                    />
                                    {t("validation.special")}
                                </Input.Condition>
                            </Input.Conditions>
                        </Input.Content>
                    </Input.Container>

                    <Input.Container>
                        <Input.Content>
                            <Input.StyledLabel htmlFor="password-confirmation">
                                {t("new-confirm-password")}
                            </Input.StyledLabel>
                            <Input.Wrap>
                                <Input.Field
                                    type="password"
                                    id="password-confirmation"
                                    autoComplete="off"
                                    placeholder="Password"
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    confirm={confirmPassword ? 1 : 0}
                                    error={error && error.confirmation ? 1 : 0}
                                />
                                {isConfirmPassword && (
                                    <Input.Icon
                                        icon={faCheck}
                                        size="2x"
                                        confirm={1}
                                    />
                                )}
                            </Input.Wrap>
                            {error && error.confirmation && (
                                <Input.Message>
                                    {t(`error.${error.confirmation}`)}
                                </Input.Message>
                            )}
                        </Input.Content>
                    </Input.Container>

                    <Input.Container>
                        <Button type="submit" disabled={disabled ? 1 : 0}>
                            {t("change-password")}
                        </Button>
                    </Input.Container>
                </StyledForm>
            </Container>
        </StyledMain>
    );
}

export default ChangePasswordPage;
