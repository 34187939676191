import styled from "styled-components";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    box-sizing: border-box;
    padding: 0 3px;
    max-width: 1200px;

    @media screen and (max-width: 768px) {
        width: 315px;
        padding: 0;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 84px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 48px;
    }
`;

const StyledSubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
    }
`;

const Contents = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`;

const Content = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
`;

const StyledParagraph = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

const StyledWriter = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    align-self: stretch;
`;

const Row = styled.ul`
    display: block;
    list-style-type: disc;
    padding-left: 30px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;

    @media screen and (max-width: 768px) {
        padding-left: 25px;
    }
`;
const Col = styled.li`
    display: list-item;
    a {
        text-decoration: none;
        color: #92b4ec;
    }
`;

function PrivacyPolicyPage() {
    return (
        <StyledMain>
            <Container>
                <TitleContainer>
                    <StyledTitle>개인정보 처리방침</StyledTitle>
                    <StyledSubTitle>마지막 업데이트: 2022-08-23</StyledSubTitle>
                </TitleContainer>
                <Contents>
                    <Content>
                        <StyledSubTitle>
                            개인정보 처리방침의 의의
                        </StyledSubTitle>
                        <StyledParagraph>
                            본 개인정보 처림방침은 University College London
                            Korean Society(UCLKS)를 위한 것으로, 다음과 같은
                            경우 여러분이 저희 서비스를 사용하실 때 여러분의
                            어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며,
                            필요에 따라 누구와 이를 공유(‘위탁 또는 제공’)하며,
                            이용목적을 달성한 정보를 언제·어떻게 파기하는지 등과
                            관련한 정보를 제공합니다. 사이트를 사용하거나
                            회원가입하실 경우 여려분은 본 개인정보 처리방침에
                            동의하는 것입니다. 동의하지 않는 경우 사이트를
                            사용하지 마십시오.
                            <br />
                            <br />
                            UCLKS는 언제든지 본 개인정보 처림방침의 일부를 변경,
                            수정, 추가 또는 제거할 권리를 가집니다. 본 개인정보
                            처리방침에 변경 사항이 있는지 주기적으로 확인할
                            책임은 여러분에게 있습니다. 본 개인정보 처림방침의
                            “마지막 업데이트” 이후 사이트를 계속 사용하면 변경
                            사항에 동의하는 것입니다. 귀하가 본 개인정보
                            처림방침을 준수하는 경우에 한해 UCLKS는 여러분에게
                            사이트에 접속하고 사이트를 사용할 수 있는 개인적이고
                            비독점적이며 양도 불가능하고 제한된 권한을
                            부여합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>수집하는 개인정보</StyledSubTitle>
                        <StyledParagraph>
                            먼저 여러분은 회원가입을 하지 않아도 웹사이트
                            대부분의 서비스를 사용하실수 있습니다. 다만 더욱더
                            확장된 서비스를 위해서 필요한 최소한의 개인정보를
                            수집합니다. 회원가입 및 서비스 이용 과정에서
                            이용자가 개인정보 수집에 대해 동의를 하고 직접
                            정보를 입력하는 경우, 해당 개인정보를 수집합니다.
                        </StyledParagraph>
                        <Row>
                            <Col>
                                이름, 이메일, 비밀번호를 필수항목으로
                                수집합니다.
                            </Col>
                            <Col>
                                생일, 성별, 학위, 과는 선택항목으로, 이 정보
                                사용을 원치 않다면 작성하지 않으시면 됩니다.
                            </Col>
                        </Row>
                        <StyledParagraph>
                            필요한 경우, 여러분의 동의 또는 관련 법률에 의해
                            허용된 대로 학생정보를 처리합니다.
                            <br />
                            여러분이 제출하는 모든 등록 정보가 사실이며 정확하고
                            최신인 것을 알리고, 이 정보의 정확성을 유지하기
                            위해서 등록 정보를 업데이트해야 합니다.
                            <br />
                            UCLKS는 여려분의 정보가 사실이 아니거나 부정확한
                            정보를 제공할 경우 여러분의 계정을 일시 중단하거나
                            탈퇴하고 현재 또는 향후 사이트(또는 일부)의 사용을
                            전면 거부할 권리가 있습니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>
                            자동으로 수집되는 개인정보
                        </StyledSubTitle>
                        <StyledParagraph>
                            서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용
                            기록, 기기정보, 위치정보가 생성되어 수집될 수
                            있습니다.
                        </StyledParagraph>
                        <Row>
                            <Col>
                                로그 및 사용량 데이터: 로그 및 사용량 데이터는
                                사용자가 서비스에 액세스하거나 서비스를 사용할
                                때 서버가 자동으로 수집하고 로그 파일에 기록하는
                                서비스 관련, 진단, 사용량 및 성능 정보입니다.
                                NAT과의 상호 작용 방식에 따라 이 로그 데이터에는
                                IP 주소, 장치 정보, 브라우저 유형, 서비스 내
                                활동에 대한 설정 및 정보(예: 사용량과 관련된
                                날짜/시간 스탬프, 보기된 페이지 및 파일, 검색 및
                                사용하는 기능과 같은 기타 작업), 장치 이벤트
                                inf가 포함될 수 있습니다.구성(예: 시스템 작업,
                                오류 보고서('스위치 덤프'라고도 함) 및 하드웨어
                                설정).
                            </Col>
                            <Col>
                                장치 데이터: 여러분이 서비스를 사용하시는 데
                                사용하는 컴퓨터, 전화, 태블릿 또는 기타 장치에
                                대한 정보와 같은 장치 데이터를 수집합니다.
                                사용하는 장치에 따라 이 장치 데이터에는 IP 주소
                            </Col>
                            <Col>
                                위치 데이터: 장치의 위치에 대한 정보와 같은 위치
                                데이터를 수집합니다. 이 정보는 정확하거나
                                정확하지 않을 수 있습니다. 수집하는 정보의 양은
                                서비스에 액세스하는 데 사용하는 장치의 유형과
                                설정에 따라 달라집니다. 예를 들어 GPS 및 기타
                                기술을 사용하여 현재 위치(IP 주소 기준)를
                                알려주는 지리 위치 데이터를 수집할 수 있습니다.
                                정보에 대한 액세스를 거부하거나 장치의 위치
                                설정을 비활성화하여 이 정보를 수집하는 것을
                                허용하지 않도록 선택할 수 있습니다. 그러나
                                탈퇴를 선택하면 서비스의 특정 측면을 사용하지
                                못할 수 있습니다.
                            </Col>
                        </Row>
                    </Content>
                    <Content>
                        <StyledSubTitle>수집한 개인정보의 이용</StyledSubTitle>
                        <StyledParagraph>
                            저희 서비스(모바일 웹/앱 포함)의 회원관리, 서비스
                            개발·제공 및 향상, 안전한 인터넷 이용환경 구축 등
                            아래의 목적으로만 개인정보를 이용합니다.
                        </StyledParagraph>
                        <Row>
                            <Col>
                                회원 가입 의사의 확인, 연령 확인 및 법정대리인
                                동의 진행, 이용자 및 법정대리인의 본인 확인,
                                이용자 식별, 회원탈퇴 의사의 확인 등 회원관리를
                                위하여 개인정보를 이용합니다.
                            </Col>
                            <Col>
                                콘텐츠 등 기존 서비스 제공(광고 포함)에 더하여,
                                인구통계학적 분석, 서비스 방문 및 이용기록의
                                분석, 개인정보 및 관심에 기반한 이용자간 관계의
                                형성, 지인 및 관심사 등에 기반한 맞춤형 서비스
                                제공 등 신규 서비스 요소의 발굴 및 기존 서비스
                                개선 등을 위하여 개인정보를 이용합니다.
                            </Col>
                            <Col>
                                법령 및 이용약관을 위반하는 회원에 대한 이용
                                제한 조치, 부정 이용 행위를 포함하여 서비스의
                                원활한 운영에 지장을 주는 행위에 대한 방지 및
                                제재, 계정도용 및 부정거래 방지, 약관 개정 등의
                                고지사항 전달, 분쟁조정을 위한 기록 보존,
                                민원처리 등 이용자 보호 및 서비스 운영을 위하여
                                개인정보를 이용합니다.
                            </Col>
                            <Col>
                                이벤트 정보 및 참여기회 제공, 광고성 정보 제공
                                등 마케팅 및 프로모션 목적으로 개인정보를
                                이용합니다.
                            </Col>
                            <Col>
                                서비스 이용기록과 접속 빈도 분석, 서비스 이용에
                                대한 통계, 서비스 분석 및 통계에 따른 맞춤
                                서비스 제공 및 광고 게재 등에 개인정보를
                                이용합니다.
                            </Col>
                            <Col>
                                보안, 프라이버시, 안전 측면에서 여러분이
                                안심하고 이용할 수 있는 서비스 이용환경 구축을
                                위해 개인정보를 이용합니다.
                            </Col>
                        </Row>
                    </Content>
                    <Content>
                        <StyledSubTitle>
                            저희가 신뢰하는 법적 근거
                        </StyledSubTitle>
                        <StyledParagraph>
                            GDPR(General Data Protection Regulation) 및 영국
                            GDPR은 여러분의 개인 정보를 처리하기 위해 저희가
                            의존하는 유효한 법적 근거를 설명하도록 요구합니다.
                            그러므로 다음과 같은 법적 근거를 바탕으로 귀하의
                            개인 정보를 처리할 수 있습니다.
                        </StyledParagraph>
                        <Row>
                            <Col>
                                동의: 여러분이 웹사이트를 사용하는 경우 개인
                                정보를 특정 목적으로 사용할 수 있는 허가를
                                부여됩니다. 이에 따라 저희는 귀하의 정보를
                                정부에 제공 및 처리할 수 있습니다. 웹사이트를
                                사용하지 않으므로서 여러분의 동의를 철회할 수
                                있습니다.
                            </Col>
                            <Col>
                                법적 의무: 법 집행 기관 또는 규제 기관과의 협력,
                                법적 권리 행사 또는 방어, 또는 당사 관련 소송의
                                증거로 여러분의 정보를 공개하는 등 법적 의무를
                                준수하기 위해 필요하다고 판단되는 경우 여러분의
                                정보를 정부에 제공 및 처리할 수 있습니다.
                            </Col>
                            <Col>
                                중요 관심사: 저희는 여러분의 중요한 이익 또는
                                타인의 안전에 대한 잠재적 위협과 같은 제3자의
                                중요한 이익을 보호하기 위해 필요하다고 판단되는
                                경우 어러분의 정보를 정부에 제공 및 처리할 수
                                있습니다.
                            </Col>
                        </Row>
                    </Content>
                    <Content>
                        <StyledSubTitle>제 3자 개인 정보 공유</StyledSubTitle>
                        <StyledParagraph>
                            다음과 같은 경우 귀하의 개인 정보를 공유 할 수
                            있습니다:
                        </StyledParagraph>
                        <Row>
                            <Col>
                                사업 양도: 저희는 합병, 회사 자산의 매각, 자금
                                조달 또는 사업의 전부 또는 일부의 취득과
                                관련하여 또는 협상 중에 귀하의 정보를 다른
                                회사에 공유하거나 이전할 수 있습니다.
                            </Col>
                            <Col>
                                Google 지도 플랫폼 API 사용: 특정 Google Maps
                                플랫폼 API(예: Google Maps API, Places API)와
                                사용자 정보를 공유할 수 있습니다. Google의 개인
                                정보 보호 정책에 대한 자세한 내용은 이{" "}
                                <a href="https://policies.google.com/privacy">
                                    링크
                                </a>
                                를 참조하십시오.
                            </Col>
                        </Row>
                    </Content>
                    <Content>
                        <StyledSubTitle>개인정보의 파기</StyledSubTitle>
                        <StyledParagraph>
                            UCLKS는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시
                            지체없이 파기하고 있습니다.
                            <br />
                            단, 여러분에게 개인정보 보관기간에 대해 별도의
                            동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관
                            의무를 부과하는 경우에는 해당 기간 동안 개인정보를
                            안전하게 보관합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>
                            개인정보 보호를 위한 UCLKS가 한 노력
                        </StyledSubTitle>
                        <StyledParagraph>
                            저희는 처리하는 모든 개인 정보의 보안을 보호하기
                            위해 설계된 적절하고 합리적인 기술적 및 조직적 보안
                            조치를 구현했습니다. 저희의 안전장치와 여러분의
                            정보를 보호하기 위한 노력에도 불구하고, 인터넷이나
                            정보 저장 기술을 통한 어떠한 전자 전송도 100%
                            안전하다고 보장될 수 없기 때문에, 저희는 해커,
                            사이버 범죄자 또는 다른 허가받지 않은 제3자가 보안을
                            무너뜨리고 부적절하게 수집하지 않을 것이라고
                            약속하거나 보장할 수 없습니다. UCLKS는 여러분의 개인
                            정보를 보호하기 위해 최선을 다하겠지만, 당사
                            서비스와의 개인 정보 전송은 귀하의 책임입니다.
                            안전한 보안 환경 구축된 내에서만 저희 서비스를
                            이용하셔야 합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>개인정보 권리</StyledSubTitle>
                        <StyledParagraph>
                            일부 지역(예: EEA 및 UK)에서는 해당 데이터 보호법에
                            따라 특정 권한을 가집니다.
                        </StyledParagraph>
                        <Row>
                            <Col>
                                개인 정보의 접근성를 요청하고 사본을 얻을 수
                                있는 권리
                            </Col>
                            <Col>수정 또는 삭제를 요청할 권리</Col>
                            <Col>개인 정보의 처리를 제한할 권리</Col>
                        </Row>
                        <StyledParagraph>
                            특정 상황에 따라 데이터 이동성에 대한 권리가 포함될
                            수 있습니다. 특정 상황에서는 개인 정보 처리에 대해
                            이의를 제기할 수 있는 권리도 있습니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>오류 및 오타</StyledSubTitle>
                        <StyledParagraph>
                            본 웹사이트는 설명, 가격, 및 기타 다양한 정보를
                            포함하여, 부정확함 또는 누락된 정보가 있을
                            수있습니다. UCLKS는 부정확함 또는 누락 사항을
                            수정하고 사전통지 없이 언제든지 사이트의 정보를 변경
                            또는 업데이트 할 수 있는 권리를 보유 합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>부칙</StyledSubTitle>
                        <StyledParagraph>
                            본 약관은 한국어를 정본으로 하며, 영국의 법령이
                            적용됩니다. 이 약관은 2022년 8월 23일부터
                            시행됩니다.
                            <br />
                            <br />
                            UCLKS 이용약관의 관련하여 궁금하신 사항이 있으시면
                            uclks.committee@gmail.com로 문의 주시기 바랍니다.
                        </StyledParagraph>
                    </Content>
                </Contents>
                <StyledWriter>UCLKS IT Director 팀</StyledWriter>
            </Container>
        </StyledMain>
    );
}

export default PrivacyPolicyPage;
