import styled from "styled-components";
import { useEffect } from "react";
import { Button } from "../components/Styles/Button.styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { resendEmail } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import {
    USER_CHANGE_EMAIL_RESET,
    USER_EMAIL_RESEND_RESET,
    USER_EMAIL_RESET,
    USER_INFO_RESET,
    USER_REGISTER_RESET,
} from "../constants/userConstants";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 122px;
    gap: 30px;

    max-width: 582px;
    border-radius: 32px;
    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 768px) {
        box-shadow: none;
        width: 315px;
        height: auto;
        padding: 0;
        background: none;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;

    text-align: center;
`;

const StyledNote = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
`;

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    align-self: stretch;
`;

const Mail = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    width: 100%;
    overflow-wrap: break-word;

    color: #000000;

    text-align: center;
`;

function VerifyEmailPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const { user: info } = userInfo;
    const userRegister = useSelector((state) => state.userRegister);
    const { success, error } = userRegister;
    const userEmailResend = useSelector((state) => state.userEmailResend);
    const { resendingEmail, error: emailResendError } = userEmailResend;
    const userChangeEmail = useSelector((state) => state.userChangeEmail);
    const { emailSending: changeEmailSending, error: changeEmailError } =
        userChangeEmail;

    const { t } = useTranslation("VerifyEmailPage");

    const handleResendEmail = (e) => {
        e.preventDefault();
        dispatch(resendEmail());
    };

    useEffect(() => {
        if (
            info?.email_verified_at ||
            (changeEmailSending === undefined &&
                resendingEmail === undefined &&
                success === undefined &&
                error === undefined)
        ) {
            navigate("/");
        }
    }, [info, navigate, changeEmailSending, resendingEmail, success, error]);

    useEffect(() => {
        return () => {
            dispatch({ type: USER_EMAIL_RESEND_RESET });
            dispatch({ type: USER_EMAIL_RESET });
            dispatch({ type: USER_REGISTER_RESET });
            dispatch({ type: USER_INFO_RESET });
            dispatch({ type: USER_CHANGE_EMAIL_RESET });
        };
    }, [dispatch]);

    return (
        <StyledMain>
            <Container>
                {changeEmailSending || resendingEmail ? (
                    <StyledTitle>{t("email-sending")}</StyledTitle>
                ) : changeEmailError || emailResendError || error ? (
                    <StyledTitle>{t("email-fail")}</StyledTitle>
                ) : (
                    <StyledTitle>{t("email-success")}</StyledTitle>
                )}
                {changeEmailSending || resendingEmail ? (
                    <StyledNote>{t("note-sending")}</StyledNote>
                ) : changeEmailError || emailResendError || error ? (
                    <StyledNote>{t("note-fail")}</StyledNote>
                ) : (
                    <StyledNote>{t("note-success")}</StyledNote>
                )}
                <Wrap>
                    <Mail>{info?.email}</Mail>
                    <Button
                        type="submit"
                        width={"300px"}
                        onClick={handleResendEmail}
                    >
                        {t("resend-email")}
                    </Button>
                </Wrap>
            </Container>
        </StyledMain>
    );
}

export default VerifyEmailPage;
