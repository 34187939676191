import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    box-sizing: border-box;
    padding: 0 3px;
    max-width: 1200px;

    @media screen and (max-width: 768px) {
        width: 315px;
        padding: 0;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 84px;

    color: #000000;
`;

const StyledSubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
    }
`;

const Contents = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
`;

const Content = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
`;

const StyledParagraph = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
`;

const StyledLink = styled(Link)`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    text-decoration: none;
    color: #92b4ec;
`;

const StyledWriter = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    align-self: stretch;
`;

const Row = styled.ul`
    display: block;
    list-style-type: disc;
    padding-left: 30px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;

    @media screen and (max-width: 768px) {
        padding-left: 25px;
    }
`;
const Col = styled.li`
    display: list-item;
`;

function TermsPage() {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    return (
        <StyledMain>
            <Container>
                <TitleContainer>
                    <StyledTitle>이용약관</StyledTitle>
                    <StyledSubTitle>마지막 업데이트: 2022-08-23</StyledSubTitle>
                </TitleContainer>
                <Contents>
                    <Content>
                        <StyledSubTitle>사이트 소유권</StyledSubTitle>
                        <StyledParagraph>
                            본 이용 약관은 UCLKS 웹사이트인 www.uclks.org에
                            적용됩니다. UCLKS는 UCL(University College London)의
                            등록 단체에 있어 Gower St, London WC1E GBT에
                            위치합니다. 그 즉슨 UCLKS는 Student Union UCL의 정책
                            역시 동일하게 적용됩니다. 사이트를 사용하거나
                            회원가입하실 경우 여려분은 본 이용 약관에 동의하는
                            것입니다. 동의하지 않는 경우 사이트를 사용하지
                            마십시오.
                            <br />
                            <br />
                            UCLKS는 언제든지 본 이용 약관의 일부를 변경, 수정,
                            추가 또는 제거할 권리를 가집니다. 본 이용 약관에
                            변경 사항이 있는지 주기적으로 확인할 책임은
                            여러분에게 있습니다. 본 이용약관의 “마지막 업데이트”
                            이후 사이트를 계속 사용하면 변경 사항에 동의하는
                            것입니다. 귀하가 본 이용 약관을 준수하는 경우에 한해
                            UCLKS는 여러분에게 사이트에 접속하고 사이트를 사용할
                            수 있는 개인적이고 비독점적이며 양도 불가능하고
                            제한된 권한을 부여합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>지적 재산권</StyledSubTitle>
                        <StyledParagraph>
                            달리 명시되어 있지 않는 한, 본 사이트에 포함된 모든
                            텍스트, 그래픽, 사용자 인터페이스, 시각적
                            인터페이스, 사진, 상표, 로고, 사운드, 음악, 아트워크
                            및 컴퓨터 코드(총칭 '콘텐츠')는 해당 콘텐츠의
                            디자인, 구조, 선택, 조정, 표현, '외관과 느낌(look
                            and feel)' 및 배열을 포함하되 이에 국한되지 않으며
                            UCLKS는 소유 또는 통제하거나 사용권을 부여하거나
                            사용권을 가지고, 트레이드 드레스, 저작권, 특허 및
                            상표법과 기타 다양한 지적 재산권 및 불공정
                            경쟁법으로 보호됩니다.
                            <br />
                            <br />본 이용 약관에 명시적으로 규정된 경우를
                            제외하고 UCLKS의 명시적 사전 서면 동의 없이 사이트의
                            일부 및 콘텐츠를 다른 컴퓨터, 서버, 웹 사이트, 기타
                            게시 또는 배포 미디어나 기업체용 미디어에 어떤
                            식으로든('미러링' 포함) 복사, 재생산, 재발행,
                            업로드, 게시, 공개 전시, 인코딩, 번역, 전송 또는
                            배포해서는 안 됩니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>쿠키</StyledSubTitle>
                        <StyledParagraph>
                            본 웹사이트는 쿠키를 사용합니다. 여러분은 UCLKS
                            웹사이트의 개인정보 보호정책에 따라 쿠키사용에
                            동의하는것으로 간주됩니다. 쿠키는 본 사이트의 일부
                            영역에서 영역의 기능을 활성화 시키고 방문하는
                            사람들이 본 웹사이트를 쉽게 사용할 수 있도록 하기
                            위해 사용됩니다. 일부 제휴사/광고 파트너도 쿠키를
                            사용할 수 있습니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>개인정보</StyledSubTitle>
                        <StyledParagraph>
                            UCLKS는 개인정보 및 보안을 위해 최대한으로 노력하고
                            있습니다. 개인정보 처리방침은 본 사이트 사용에
                            적용되며, UCLKS의 개인정보 처리방침을 보려면{" "}
                            <StyledLink to="/privacy" onClick={scrollToTop}>
                                여기
                            </StyledLink>
                            를 클릭하십시오.
                            <br />본 사이트는 영국에서 배포되며 영국의 법률과
                            개인정보 사용을 볍률에 준수하므로 다른 지역에서
                            사이트에 방문하는 경우, 사이트의 지속적인 사용을
                            통해 여려분의 데이터를 영국으로 전송하며, 여려분은
                            이에 동의하는 것으로 간주됩니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>계정 및 정보 표시</StyledSubTitle>
                        <StyledParagraph>
                            여러분이 제출하는 모든 등록 정보가 사실이며 정확하고
                            최신인 것을 알리고, 이 정보의 정확성을 유지하기
                            위해서 등록 정보를 업데이트해야 합니다. UCLKS는
                            여려분의 정보가 사실이 아니거나 부정확한 정보를
                            제공할 경우 여러분의 계정을 일시 중단하거나 탈퇴하고
                            현재 또는 향후 사이트(또는 일부)의 사용을 전면
                            거부할 권리가 있습니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>사이트 이용</StyledSubTitle>
                        <StyledParagraph>
                            사이트에서 또는 사이트를 통해 제공되는 특정 기능이나
                            서비스를 사용하려면 계정을 개설해야 할 수 있습니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>사이트 사용</StyledSubTitle>
                        <StyledParagraph>
                            여러분은 사이트를 이용할 수 있는 목적 이외의 방문
                            목적은 금지되어 있습니다. 본 사이트는 특별히 승인된
                            경우를 제외하고 어떠한 상업적 노력과도 관련하여 사용
                            될 수 없습니다.
                            <br />
                            <br />
                            웹사이트의 사용자로서 아래 행동을 금지합니다.
                        </StyledParagraph>
                        <Row>
                            <Col>
                                '딥 링크', '페이지 스크랩', '로봇', '스파이더'
                                또는 기타 자동 기기, 프로그램, 알고리즘 또는
                                방법론이나 그와 유사하거나 동등한 수동 절차를
                                사용하여 사이트 또는 콘텐츠의 일부에 접근하거나
                                이를 취득, 복사 또는 모니터링하거나, 사이트 또는
                                콘텐츠의 탐색 구조나 표현을 어떤 식으로든
                                복제하거나 우회함으로써 사이트를 통해 의도적으로
                                제공되지 않은 수단으로 자료, 문서 또는 정보를
                                획득하거나 획득하려고 시도합니다. UCLKS는 해당
                                활동을 금지할 권리를 가집니다.
                            </Col>
                            <Col>
                                해킹, 암호 '마이닝' 또는 기타 불법적인 수단을
                                통해 사이트의 일부나 기능, 사이트나 UCLKS 서버에
                                연결된 기타 시스템이나 네트워크, 사이트에서 또는
                                사이트를 통해 제공되는 서비스에 대한 무단 접근
                                권한을 얻으려고 시도해서는 안 됩니다.
                            </Col>
                            <Col>
                                UCLKS 허가 없이 직접 또는 간접적으로 수집,
                                컴파일, 데이터베이스 또는 디렉토리를 생성하거나
                                컴파일하기 위해 사이트에서 데이터 또는 기타
                                컨텐츠를 체계적으로 검색합니다.
                            </Col>
                            <Col>
                                사이트 또는 사이트의 일부에 대한 액세스를
                                방지하거나 제한하도록 설계된 사이트의 모든
                                조치를 무시합니다.
                            </Col>
                            <Col>
                                다른 사람으로 가장하거나 다른 사람을 대표한다고
                                가장하거나 다른 개인 또는 단체를 사칭해서는 안
                                됩니다.
                            </Col>
                            <Col>
                                다른 사람을 괴롭히거나 학대하거나 다치게 하기
                                위해 사이트에서 얻은 모든 정보를 사용합니다.
                            </Col>
                            <Col>
                                사용자 암호와 같은 중요한 계정 정보를 얻으려는
                                시도, UCLKS나 타인의 권리를 침해하는 그 밖의
                                활동을 권유하기 위해 사용해서는 안 됩니다.
                            </Col>
                            <Col>
                                사이트 또는 콘텐츠를 불법적이거나 본 이용
                                약관에서 금지하는 목적으로 사용하거나 불법적인
                                활동 또는 UCLKS나 타인의 권리를 침해하는 그 밖의
                                활동을 권유하기 위해 사용해서는 안 됩니다.
                            </Col>
                            <Col>
                                콘텐츠의 사용 또는 복사를 금지하거나 제한하거나
                                사이트 및/또는 여기에 포함된 콘텐츠의 사용을
                                제한하는 기능을 포함하여 사이트의 보안 관련
                                기능을 방지하거나 비활성화하거나 간섭합니다.
                            </Col>
                            <Col>
                                UCLKS단체 또는 사이트를 비하하거나 더럽히거나
                                다른 방법으로 해를 입히는 행위.
                            </Col>
                            <Col>
                                사이트의 중단 없는 사용 및 즐거움을 방해하거나
                                사용, 기능을 수정, 손상, 중단, 변경 또는
                                방해하는 바이러스, 트로이 목마 또는 기타
                                자료(또는 업로드 또는 전송 시도)의 과도한 사용
                                및 스팸 발송(반복 텍스트의 지속적인 게시),
                                사이트의 기능, 운영 또는 유지 관리.
                            </Col>
                            <Col>
                                스크립트를 사용하여 주석이나 메시지를 보내거나
                                데이터 마이닝, 로봇 또는 유사한 데이터 수집 및
                                추출 도구를 사용하는 등 시스템의 자동 사용에
                                관여합니다.
                            </Col>
                            <Col>
                                콘텐츠에서 저작권 또는 기타 소유권 고지를
                                삭제합니다.
                            </Col>
                            <Col>
                                사이트 또는 사이트에 연결된 네트워크 또는
                                서비스에 간섭, 중단 또는 과도한 부담을
                                발생시킵니다.
                            </Col>
                            <Col>
                                Flash, PHP, HTML, JavaScript 또는 기타 코드를
                                포함하지만 이에 국한되지 않는 사이트의
                                소프트웨어를 복사하거나 수정합니다.
                            </Col>
                            <Col>
                                해당 법률에 의해 허용된 경우를 제외하고,
                                사이트의 일부를 구성하거나 구성하는 모든
                                소프트웨어를 해독 또는 리버스 엔지니어링합니다.
                            </Col>
                            <Col>
                                그 어떠한 방법으로 사용자의 이름 및 메일 주소를
                                수집하거나 자동 또는 거짓으로 사용자 계정을
                                만드는 등 사이트를 무단 사용합니다.
                            </Col>
                            <Col>
                                상품 및 서비스 판매를 광고하거나 제안하기 위해
                                사이트를 사용합니다.
                            </Col>
                            <Col>계정을 판매하거나 양도합니다.</Col>
                        </Row>
                    </Content>
                    <Content>
                        <StyledSubTitle>다른 사이트 링크</StyledSubTitle>
                        <StyledParagraph>
                            이 사이트에는 다른 독립적 제3자 웹 사이트('연결된
                            사이트') 링크가 포함되어 있을 수 있습니다. 이러한
                            연결된 사이트는 방문자 편의 목적으로만 제공됩니다.
                            해당 연결된 사이트는 UCLKS가 제어할 수 없으며,
                            UCLKS는 해당 연결된 사이트에 포함된 정보나 자료 등
                            해당 연결된 사이트의 콘텐츠에 대해 책임을 지지
                            않으며 이를 보증하지 않습니다. 귀하는 이러한 연결된
                            사이트와의 상호 작용에 대해 독립적인 판단을 내려야
                            합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>여러분이 게시한 자료</StyledSubTitle>
                        <StyledParagraph>
                            본 웹사이트의 특정 영역은 여러분에 의해 정, 자려 및
                            데이터(댓글)를 게시하고 교환할수있는 기회를
                            제공합니다. UCLKS는 모든 게시물 댓글을 모니터링하고
                            본 약관을 위반하는 부적절한, 모욕적인 또는 기타
                            방법으로 간주될 경우 영구적으로 제거할 권리를
                            보유합니다.
                            <br />
                            <br />
                            여러분은 웹사이트를 사용하므로서:
                        </StyledParagraph>
                        <Row>
                            <Col>
                                당사 웹 사이트에 댓글을 게시할 수 있으며, 이를
                                위해 필요한 모든 라이센스와 동의가 있습니다.
                            </Col>
                            <Col>
                                게시물 및 댓글은 저작권, 특허 또는 상표 또는
                                제3자의 기타 소유권을 제한 없이 포함하여 어떠한
                                지적 재산권도 침해하지 않습니다.
                            </Col>
                            <Col>
                                게시물 및 댓글은 명예훼손, 명예훼손, 모욕적,
                                외설적이거나 기타 불법적인 자료 또는 사생활
                                침해를 포함하지 않는다.
                            </Col>
                            <Col>
                                댓글은 비즈니스 또는 관습, 상업 활동 또는 불법
                                활동을 권유하거나 홍보하는 데 사용되지 않습니다.
                            </Col>
                            <Col>
                                여러분은 이에 따라 타인이 귀하의 의견을 모든
                                양식, 형식 또는 매체에서 사용, 복제, 편집할 수
                                있는 비독점 프리 라이센스를 UCLKS에 부여합니다.
                            </Col>
                        </Row>
                    </Content>
                    <Content>
                        <StyledSubTitle>오류 및 오타</StyledSubTitle>
                        <StyledParagraph>
                            본 웹사이트는 설명, 가격, 및 기타 다양한 정보를
                            포함하여, 부정확함 또는 누락된 정보가 있을
                            수있습니다. UCLKS는 부정확함 또는 누락 사항을
                            수정하고 사전통지 없이 언제든지 사이트의 정보를 변경
                            또는 업데이트 할 수 있는 권리를 보유 합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>본 이용약관의 위반</StyledSubTitle>
                        <StyledParagraph>
                            UCLKS는 여러분이 사이트 사용에 대한 조사 또는 고소와
                            관련하여 필요하다고 판단되거나 UCLKS의 권리나 재산
                            또는 UCLKS의 회원을 포함한 사이트 방문자나 사용자의
                            권리 또는 재산에 고의 또는 과실로 피해나 방해를
                            초래하는 자를 식별하거나, 그러한 자에게 연락하거나
                            법적 조치를 취하기 위해 필요하다고 판단되는 경우
                            귀하에 대해 보유하고 있는 정보(귀하의 신원 포함)를
                            공개할 수 있습니다. UCLKS는 언제든 관련 법률, 규정,
                            법적 절차 또는 정부 요청을 준수하는 데 필요하다고
                            간주하는 모든 정보를 공개할 권리를 가집니다. 또한
                            UCLKS 사기 방지 목적으로 다른 기업 및 조직과 정보를
                            교환하는 등 관련 법률에서 공개를 요구하거나
                            허용한다고 판단하는 경우 귀하의 정보를 공개할 수
                            있습니다.
                            <br />
                            <br />
                            여러분은 UCLKS가 법적으로 요구되거나 (1) 법적 절차
                            준수, (2) 본 이용 약관 집행, (3) 해당 데이터가
                            타인의 권리를 침해한다는 주장에 대한 대응 또는 (4)
                            UCLKS 및 그 직원, 사이트 사용자 또는 방문자 및
                            대중의 권리, 재산 또는 개인적 안전의 보호에
                            합리적으로 필요하다고 판단하는 경우 귀하가
                            사이트에서 또는 사이트를 통해 제공되거나 사이트에서
                            또는 사이트를 통해 제공되는 서비스를 통해 UCLKS에
                            전송 또는 통신한 내용을 보존할 수 있으며 해당
                            데이터를 공개할 수 있음을 인정하고 이에 동의합니다.
                        </StyledParagraph>
                    </Content>
                    <Content>
                        <StyledSubTitle>부칙</StyledSubTitle>
                        <StyledParagraph>
                            본 약관은 한국어를 정본으로 하며, 영국의 법령이
                            적용됩니다. 이 약관은 2022년 8월 23일부터
                            시행됩니다.
                            <br />
                            <br />
                            UCLKS 이용약관의 관련하여 궁금하신 사항이 있으시면
                            uclks.committee@gmail.com로 문의 주시기 바랍니다.
                        </StyledParagraph>
                    </Content>
                </Contents>
                <StyledWriter>UCLKS IT Director 팀</StyledWriter>
            </Container>
        </StyledMain>
    );
}

export default TermsPage;
