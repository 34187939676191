import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledNav = styled(NavLink)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-decoration: none;

    -webkit-tap-highlight-color: transparent;
`;

function shapeType(shape) {
    switch (shape) {
        case "left":
            return "8px 16px 8px 24px";
        case "middle":
            return "8px 16px";
        case "right":
            return "8px 24px 8px 16px";
        default:
            return "8px 16px";
    }
}

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: ${({ shape }) => shapeType(shape)};

    height: 84px;

    background-color: ${({ theme: { colours } }) => colours.default.MistyGrey};
`;

const StyledText = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: ${({ theme: { colours } }) => colours.default.PureBlack};

    display: flex;
    align-items: center;
    text-align: center;

    position: relative;
    z-index: 3;
`;

const DecorationContainer = styled.div`
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    height: 16px;
    position: relative;
`;

const Retangle = styled.span`
    background-color: ${({ theme: { colours } }) => colours.default.MistyGrey};
    border-radius: 0px 0px 8px 8px;
    height: 16px;
    flex-grow: 1;
`;

const Block = styled.span`
    width: 8px;
    height: 8px;
    background-color: ${({ theme: { colours } }) => colours.default.MistyGrey};

    position: ${({ enabled }) => (enabled ? "absolute" : "static")};
    left: ${({ position }) => (position === "left" ? "-8px" : "auto")};
    right: ${({ position }) => (position === "right" ? "-8px" : "auto")};
    top: 0px;
    z-index: -1;

    &::before {
        content: "";
        width: 8px;
        height: 8px;
        background-color: ${({ colour, theme: { colours } }) =>
            colour ? colours.default[colour] : colours.default.PureWhite};
        position: absolute;
        border-radius: ${({ position }) =>
            position === "left" ? "0px 8px 0px 0px" : "8px 0px 0px 0px"};
        z-index: -2;
    }
`;

const GNBElement = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    ${StyledNav} {
        &.active {
            ${StyledText} {
                color: ${({ theme: { colours } }) => colours.default.PureWhite};
                @keyframes moveDown {
                    from {
                        bottom: 0px;
                    }
                    to {
                        bottom: -16px;
                    }
                }
                animation: 0.2s ease-in-out 1 forwards moveDown;
            }

            ${DecorationContainer} {
                display: flex;
                @keyframes moveIn {
                    from {
                        bottom: 16px;
                    }
                    to {
                        bottom: 0px;
                    }
                }
                animation: 0.2s ease-in-out 1 forwards moveIn;
            }
        }
    }
`;

export function LeftGNBElement({ text, to, colour }) {
    return (
        <GNBElement>
            <StyledNav to={to}>
                <TextBox shape="left">
                    <StyledText>{text}</StyledText>
                </TextBox>
                <DecorationContainer>
                    <Block position="left" colour={colour} />
                    <Retangle />
                    <Block position="right" enabled colour={colour} />
                </DecorationContainer>
            </StyledNav>
        </GNBElement>
    );
}

export function MiddleGNBElement({ text, to, colour }) {
    return (
        <GNBElement>
            <StyledNav to={to}>
                <TextBox shape="middle">
                    <StyledText>{text}</StyledText>
                </TextBox>
                <DecorationContainer>
                    <Block position="left" enabled colour={colour} />
                    <Retangle />
                    <Block position="right" enabled colour={colour} />
                </DecorationContainer>
            </StyledNav>
        </GNBElement>
    );
}

export function RightGNBElement({ text, to, colour }) {
    return (
        <GNBElement>
            <StyledNav to={to}>
                <TextBox shape="right">
                    <StyledText>{text}</StyledText>
                </TextBox>
                <DecorationContainer>
                    <Block position="left" enabled colour={colour} />
                    <Retangle />
                    <Block position="right" colour={colour} />
                </DecorationContainer>
            </StyledNav>
        </GNBElement>
    );
}
