import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import kakaotalk from "../assets/images/question_small_mono_pc_2X.png";

const InfoIcon = styled(FontAwesomeIcon)`
    width: 32px;
    height: 32px;
    color: #ffffff;
`;

const InfoImage = styled.img`
    height: 32px;
`;

const InfoIconLink = styled.a`
    height: 32px;
`;

const InfoIcons = styled.div`
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    overflow: hidden;

    &::after {
        content: "";
        width: 3px;
        align-self: stretch;
        background: #ffffff;
        border-radius: 2px;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    gap: 16px;

    box-sizing: border-box;
    position: ${({ offset }) => (offset > 538 || offset === null ? "fixed" : "absolute")};
    bottom: ${({ offset }) => (offset > 538 || offset === null? "32px" : "601px")};
    right: 32px;

    background: #92b4ec;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
        position: ${({ offset }) => (offset > 888 || offset === null ? "fixed" : "absolute")};
        bottom: ${({ offset }) => (offset > 888 || offset === null ? "32px" : "918px")};
        right: 30px;
    }

    &:hover {
        ${InfoIcons} {
            display: flex;

            @keyframes increase {
                from {
                    width: 0%;
                }
                to {
                    width: 100%;
                }
            }
            animation-duration: 0.2s;
            animation-timing-function: ease-in-out;
            animation-delay: 0s;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-fill-mode: forwards;
            animation-play-state: running;
            animation-name: increase;
        }
    }
`;

function Info() {
    const [offset, setOffset] = useState(null);

    useEffect(() => {
        const html = document.documentElement;
        const handleFollow = () => {
            const height = html.offsetHeight - html.clientHeight;
            const offset = height - window.scrollY;
            setOffset(offset);
        };

        window.addEventListener("scroll", handleFollow);
        return () => {
            window.removeEventListener("scroll", handleFollow);
        };
    });

    return (
        <Container offset={offset}>
            <InfoIcons>
                <InfoIconLink href="https://open.kakao.com/o/gMDC7iwe">
                    <InfoImage src={kakaotalk} alt="kakaotalk" />
                </InfoIconLink>
            </InfoIcons>
            <InfoIcon icon={faQuestionCircle} size={"2xl"} />
        </Container>
    );
}

export default Info;
