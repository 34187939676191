import { useTranslation } from "react-i18next";
import styled from "styled-components";
import design_pc from "../assets/images/committee_design_pc.svg";
import design_mobile from "../assets/images/committee_design_mobile.svg";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    box-sizing: border-box;
    max-width: 1200px;

    @media screen and (max-width: 768px) {
        width: 315px;
        gap: 30px;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    box-sizing: border-box;
    padding: 8px 16px;

    width: 785px;
    height: 100px;

    background: #92b4ec;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
        width: auto;
        align-self: stretch;
    }
`;

const Title = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 70px;
    line-height: 84px;

    color: #ffffff;

    .introduce {
        color: #92b4ec;
        text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff,
            0 -1px #ffffff;
    }

    @media screen and (max-width: 768px) {
        font-weight: 900;
        font-size: 36px;
    }
`;

const Design = styled.div`
    width: 167px;
    height: 283px;
    right: 0px;

    position: absolute;

    background-image: url(${design_pc});
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 0;

    @media screen and (max-width: 768px) {
        background-image: url(${design_mobile});
        width: 148px;
        height: 236px;
    }
`;

const Committes = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    gap: 30px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const CommitteCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;
    box-sizing: border-box;
    padding: 32px;

    position: relative;
    width: 378px;
    height: 283px;

    background: #606060;
    border-radius: 32px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        width: 315px;
        height: 235px;
    }
`;

const Image = styled.img`
    width: 148px;
    height: 219px;
    object-fit: cover;
    border-radius: 8px;
    z-index: 1;

    @media screen and (max-width: 768px) {
        width: 115.56px;
        height: 171px;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    box-sizing: border-box;
    gap: 10px;

    width: 109px;

    align-self: stretch;
    z-index: 1;

    @media screen and (max-width: 768px) {
        width: 87px;
    }
`;

const Name = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #ffffff;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
    }
`;

const Role = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: end;

    color: #ffffff;
    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 21px;
    }
`;

const Study = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #ffffff;

    word-break: word-break;
`;

function CommitteePage() {
    const { t, i18n } = useTranslation("CommitteePage");
    return (
        <StyledMain>
            <Container>
                <TitleContainer>
                    {i18n.language === "ko" ? (
                        <Title>
                            <span>임원</span>
                            <span className="introduce">을 소개합니다!</span>
                        </Title>
                    ) : (
                        <Title>
                            <span className="introduce">Our </span>
                            <span>Committees</span>
                        </Title>
                    )}
                </TitleContainer>
                <Committes>
                    <CommitteCard>
                        <Design />
                        <Image src="/committes/cherin.jpg" />
                        <Info>
                            <Name>신채린</Name>
                            <Role>{t("president")}</Role>
                            <Study>
                                BSc
                                <br />
                                Cancer Biomedicine
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/hanjoon.jpg" />
                        <Info>
                            <Name>조한준</Name>
                            <Role>{t("vice-president")}</Role>
                            <Study>
                                BSc
                                <br />
                                Human Sciences
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/woosuyun.jpg" />
                        <Info>
                            <Name>우수연</Name>
                            <Role>{t("treasurer")}</Role>
                            <Study>
                                BSc
                                <br />
                                Biomedical Sciences
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/lina.jpg" />
                        <Info>
                            <Name>박리나</Name>
                            <Role>{t("welfare-officer")}</Role>
                            <Study>
                                MSci
                                <br />
                                Meidical Innovation and Enterprice
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/yangeu.jpg" />
                        <Info>
                            <Name>양유진</Name>
                            <Role>{t("senior-advisor")}</Role>
                            <Study>
                                MPharm
                                <br />
                                Pharmacy
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/sunghyun.jpg" />
                        <Info>
                            <Name>한성현</Name>
                            <Role>{t("it-director")}</Role>
                            <Study>
                                BSc
                                <br />
                                Computer Science
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/junyoung.jpg" />
                        <Info>
                            <Name>고준영</Name>
                            <Role>{t("public-relation")}</Role>
                            <Study>
                                BSc
                                <br />
                                Civil Engineering
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/hyuna.jpg" />
                        <Info>
                            <Name>김현아</Name>
                            <Role>{t("academics")}</Role>
                            <Study>
                                BSc
                                <br />
                                Philosophy, Polictics and Economics
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/jimin.jpg" />
                        <Info>
                            <Name>배지민</Name>
                            <Role>{t("academics")}</Role>
                            <Study>
                                BSc
                                <br />
                                Management Science
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/leesuhyun.jpg" />
                        <Info>
                            <Name>이수현</Name>
                            <Role>{t("events")}</Role>
                            <Study>
                                BA
                                <br />
                                Education
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/seowoo.jpg" />
                        <Info>
                            <Name>이서우</Name>
                            <Role>{t("events")}</Role>
                            <Study>
                                BSc
                                <br />
                                Chemistry
                            </Study>
                        </Info>
                    </CommitteCard>

                    <CommitteCard>
                        <Design />
                        <Image src="/committes/ahrim.jpg" />
                        <Info>
                            <Name>이아림</Name>
                            <Role>{t("event-manager")}</Role>
                            <Study>
                                BSc
                                <br />
                                Mathematics
                            </Study>
                        </Info>
                    </CommitteCard>
                </Committes>
            </Container>
        </StyledMain>
    );
}

export default CommitteePage;
