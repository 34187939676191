import styled from "styled-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { verifyEmail } from "../actions/userActions";
import Input from "../components/Styles/Input.styled";
import { USER_EMAIL_RESET, USER_INFO_RESET } from "../constants/userConstants";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 122px;
    gap: 30px;

    border-radius: 32px;
    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 768px) {
        box-shadow: none;
        width: 315px;
        height: auto;
        padding: 0;
        background: none;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;
`;

const StyledText = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;

    color: #000000;
    display: inline;
`;

const Button = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;
    align-self: stretch;

    background: #92b4ec;
    border-radius: 16px;

    text-decoration: none;

    border: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #ffffff;
    cursor: pointer;
    &:active {
        background: transparent;
        padding: 5px 16px;
        border: 3px solid #92b4ec;
        color: #92b4ec;
    }
`;

function EmailVerifiedPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, hash } = useParams();
    const search = useLocation().search;
    const userEmailVerify = useSelector((state) => state.userEmailVerify);
    const { error, loading, message } = userEmailVerify;

    const { t } = useTranslation("EmailVerifiedPage");

    useEffect(() => {
        if (!id || !hash || !search) {
            navigate("/");
        } else {
            dispatch(verifyEmail(id, hash, search));
        }

        return () => {
            dispatch({ type: USER_EMAIL_RESET });
            dispatch({ type: USER_INFO_RESET });
        };
    }, [id, hash, search, navigate, dispatch]);

    return (
        <StyledMain>
            <Container>
                <StyledTitle>{t("title")}</StyledTitle>
                {loading ? (
                    <StyledText>{t("loading")}</StyledText>
                ) : error ? (
                    <StyledText>{error}</StyledText>
                ) : (
                    <StyledText>{t(message)}</StyledText>
                )}
                <Input.Container>
                    <Button to="/">{t("back")}</Button>
                </Input.Container>
            </Container>
        </StyledMain>
    );
}

export default EmailVerifiedPage;
