import * as constants from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_LOGIN_REQUEST:
            return { loading: true };
        case constants.USER_LOGIN_SUCCESS:
            return {
                isLoggedIn: true,
                loading: false,
            };
        case constants.USER_LOGIN_RESET:
            return {};
        case constants.USER_LOGIN_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_LOGOUT_REQUEST:
            return { loading: true };
        case constants.USER_LOGOUT_SUCCESS:
            return { loading: false };
        case constants.USER_LOGOUT_FAIL:
            return { error: action.payload };
        default:
            return state;
    }
};

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_REGISTER_REQUEST:
            return { loading: true };
        case constants.USER_REGISTER_SUCCESS:
            return {
                loading: false,
                success: true,
            };
        case constants.USER_REGISTER_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_REGISTER_RESET:
            return {};
        default:
            return state;
    }
};

export const userForgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_FORGOT_PASSWORD_REQUEST:
            return { loading: true };
        case constants.USER_FORGOT_PASSWORD_SUCCESS:
            return { loading: false, message: action.payload };
        case constants.USER_FORGOT_PASSWORD_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const userInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_INFO_REQUEST:
            return { loading: true };
        case constants.USER_INFO_SUCCESS:
            return { loading: false, user: action.payload };
        case constants.USER_INFO_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_INFO_RESET:
            return {};
        default:
            return state;
    }
};

export const userProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_PROFILE_REQUEST:
            return { loading: true };
        case constants.USER_PROFILE_SUCCESS:
            return { loading: false, user: action.payload };
        case constants.USER_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const userUpdateProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_UPDATE_PROFILE_REQUEST:
            return { loading: true };
        case constants.USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true };
        case constants.USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_UPDATE_PROFILE_RESET:
            return {};
        default:
            return state;
    }
};

export const userEmailVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_EMAIL_REQUEST:
            return { loading: true };
        case constants.USER_EMAIL_SUCCESS:
            return { loading: false, message: action.payload };
        case constants.USER_EMAIL_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_EMAIL_RESET:
            return {};
        default:
            return state;
    }
};

export const userEmailResendReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_EMAIL_RESEND_REQUEST:
            return { resendingEmail: true };
        case constants.USER_EMAIL_RESEND_SUCCESS:
            return { resendingEmail: false, message: action.payload };
        case constants.USER_EMAIL_RESEND_FAIL:
            return { resendingEmail: false, error: action.payload };
        case constants.USER_EMAIL_RESEND_RESET:
            return {};
        default:
            return state;
    }
};

export const userChangePassowrdReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_CHANGE_PASSWORD_REQUEST:
            return { loading: true };
        case constants.USER_CHANGE_PASSWORD_SUCCESS:
            return { loading: false, success: true };
        case constants.USER_CHANGE_PASSWORD_FAIL:
            return { loading: false, error: action.payload };
        case constants.USER_CHANGE_PASSWORD_RESET:
            return {};
        default:
            return state;
    }
};

export const userChangeEmailReducer = (state = {}, action) => {
    switch (action.type) {
        case constants.USER_CHANGE_EMAIL_REQUEST:
            return { emailSending: true };
        case constants.USER_CHANGE_EMAIL_SUCCESS:
            return { emailSending: false, message: action.payload };
        case constants.USER_CHANGE_EMAIL_FAIL:
            return { emailSending: false, error: action.payload };
        case constants.USER_CHANGE_EMAIL_RESET:
            return {};
        default:
            return state;
    }
};
