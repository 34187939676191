import HomePage from "./HomePage";
import Test from "./Test";
import NotFound from "./NotFound";
import Footer from "./Footer";
import Header from "./Header";
import Register from "./Register";
import Membership from "./Membership";
import LoginPage from "./LoginPage";
import EmailVerifiedPage from "./EmailVerifiedPage";
import VerifyEmailPage from "./VerifyEmailPage";
import ChangePasswordPage from "./ChangePasswordPage";
import PasswordChangedPage from "./PasswordChangedPage";
import ProfilePage from "./ProfilePage";
import IntroducePage from "./IntroducePage";
import ModuleFeedbackPage from "./ModuleFeedbackPage";
import DepartmentPage from "./DepartmentPage";
import FeedbackPage from "./FeedbackPage";
import CommentBox from "./CommentBox";
import CommitteePage from "./CommitteePage";
import Dropdown from "./Dropdown";

const Pages = {
    HomePage,
    Test,
    NotFound,
    Footer,
    Header,
    Register,
    IntroducePage,
    Membership,
    LoginPage,
    EmailVerifiedPage,
    VerifyEmailPage,
    ChangePasswordPage,
    PasswordChangedPage,
    ProfilePage,
    ModuleFeedbackPage,
    DepartmentPage,
    FeedbackPage,
    CommentBox,
    CommitteePage,
    Dropdown,
};

export default Pages;
