import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { USER_CHANGE_PASSWORD_RESET } from "../constants/userConstants";
import { useEffect } from "react";
import Input from "../components/Styles/Input.styled";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 64px 122px;
    gap: 30px;

    border-radius: 32px;
    background: #f2f2f2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 768px) {
        box-shadow: none;
        width: 315px;
        height: auto;
        padding: 0;
        background: none;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    color: #000000;

    width: 300px;

    @media screen {
        text-align: center;
        font-size: 40px;
        line-height: 48px;
    }
`;

const StyledNote = styled.p`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;

    color: #000000;
`;

const Button = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;
    align-self: stretch;

    background: #92b4ec;
    border-radius: 16px;

    text-decoration: none;

    border: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    color: #ffffff;
    cursor: pointer;
    &:active {
        background: transparent;
        padding: 5px 16px;
        border: 3px solid #92b4ec;
        color: #92b4ec;
    }
`;

function PasswordChangedPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { isLoggedIn } = userLogin;

    const userChangePassword = useSelector((state) => state.userChangePassword);
    const { success } = userChangePassword;

    const { t } = useTranslation("PasswordChangedPage");

    useEffect(() => {
        if (!isLoggedIn || success === false) {
            navigate("/");
        }
    }, [isLoggedIn, success, navigate]);

    useEffect(() => {
        return () => {
            dispatch({ type: USER_CHANGE_PASSWORD_RESET });
        };
    }, [dispatch]);
    return (
        <StyledMain>
            <Container>
                <StyledTitle>{t("title")}</StyledTitle>
                <StyledNote>{t("note")}</StyledNote>
                <Input.Container>
                    <Button to="/profile">{t("back")}</Button>
                </Input.Container>
            </Container>
        </StyledMain>
    );
}

export default PasswordChangedPage;
