import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import design_pc from "../assets/images/home_design_pc.svg";
import design_mobile from "../assets/images/home_design_mobile.svg";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

const BannerSection = styled.section`
    height: 336px;
    width: 100%;

    @media screen and (max-width: 768px) {
        position: relative;
        height: 480px;
        display: flex;
        justify-content: center;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    position: absolute;
    left: calc(50% - 336px / 2 + 313px);
    top: 188px;

    @media screen and (max-width: 768px) {
        position: static;
    }
`;

const StyledTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 96px;
    line-height: 115px;

    color: #000000;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 16px;

    background: #92b4ec;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
        font-size: 86px;
        line-height: 103px;
        padding: 0;
        width: 315px;
    }
`;

const StyledSubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;

    color: #606060;

    &.en {
        font-size: 28px;
        line-height: 33px;
    }
`;

const Image = styled.img`
    width: 596px;
    height: 596px;
    position: absolute;
    left: calc(50% - 596px / 2 - 321px);
    top: 71px;

    @media screen and (max-width: 768px) {
        width: 368px;
        height: 368px;
        left: calc(50% - 368px / 2 - 7.5px);
        top: 176px;
    }
`;

const AnnoucementSection = styled.section`
    background-image: url(${design_pc});
    background-repeat: repeat-x;
    background-position: center;
    background-size: 1280px 623px;
    box-sizing: border-box;
    padding: 64px 0px;
    height: 623px;
    width: 100%;
    z-index: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 30px;

    @media screen and (max-width: 768px) {
        background-image: url(${design_mobile});
        background-size: 576px 889px;
        height: 889px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 102px 0 30px 0;
        gap: 15px;
    }
`;

const ComingEvent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        order: 0;
        gap: 4px;
    }
`;

const ComingSoonTitle = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 76px;

    color: #ffffff;

    @media screen and (max-width: 768px) {
        font-size: 40px;
        line-height: 48px;
    }
`;

const NextEvent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    @media screen and (max-width: 768px) {
        order: 1;
        align-items: flex-start;
    }
`;

const NextEventTitle = styled.h4`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #ffffff;
`;

const Hanpass = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    @media screen and (max-width: 768px) {
        order: 2;
    }
`;

const HanPassTitle = styled.h5`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    display: flex;
    align-items: flex-end;

    color: #ffffff;
    white-space: pre-line;
`;

const HanPassAnchor = styled.a`
    text-decoration: none;
`;

const InstaIcon = styled(FontAwesomeIcon)`
    width: 16px;
    height: 16px;
`;

const InstaButton = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    gap: 4px;

    background: #ffffff;
    border-radius: 8px;

    position: absolute;
    right: 16px;
    bottom: 16px;

    text-decoration: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;

    color: #000000;

    transition: 0.2s;

    &.free {
        padding: 8px;
        gap: 16px;

        font-weight: 800;
        font-size: 20px;
        line-height: 24px;

        position: relative;
        right: auto;
        bottom: auto;

        align-self: stretch;

        border-radius: 16px;

        ${InstaIcon} {
            width: 32px;
            height: 32px;
        }

        @media screen and (max-width: 768px) {
            font-size: 12px;
            line-height: 14px;

            border-radius: 8px;

            ${InstaIcon} {
                width: 16px;
                height: 16px;
            }
        }
    }
`;

const LargeCard = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 32px;
    gap: 30px;

    position: relative;

    width: 582px;
    height: 389px;

    background-color: ${({ colour }) => (colour ? colour : "#ffffff")};
    border-radius: 16px;
    transition: 0.2s;

    &:hover {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);
    }

    @media screen and (max-width: 768px) {
        padding: 16px;
        gap: 15px;

        width: 315px;
        height: 211px;

        border-radius: 16px;
    }

    &.undefined {
        h1 {
            font-family: "Pretendard Variable";
            font-style: normal;
            font-weight: 900;
            font-size: 64px;
            line-height: 76px;

            color: #000000;
            white-space: pre-line;

            @media screen and (max-width: 768px) {
                font-size: 28px;
                line-height: 33px;
            }
        }
    }
`;

const LargeCardContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    flex-grow: 1;
    align-self: stretch;
`;

const LargeCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;

    align-self: stretch;
`;

const LargeCardContentTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    text-align: center;

    color: #ffffff;

    align-self: stretch;
    white-space: pre-line;

    &.en {
        font-size: 48px;
    }

    @media screen and (max-width: 768px) {
        font-size: 32px;

        &.en {
            font-size: 24px;
        }
    }
`;

const LargeCardContentDate = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;
    text-align: center;

    color: #ffffff;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

const LargeCardContentTime = styled.h3`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #ffffff;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 14px;
    }
`;

const LargeCardContentLocation = styled.h4`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;

    align-self: stretch;
    white-space: pre-line;
    width: 228px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 14px;
        width: 126.8px;
    }
`;

const LargeCardImageMask = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    overflow: hidden;

    width: 260px;
    height: 325px;

    @media screen and (max-width: 768px) {
        border-radius: 8px;
        width: 143.2px;
        height: 179px;
    }
`;

const LargeCardImage = styled.img`
    object-fit: cover;
    height: 325px;
    width: auto;

    @media screen and (max-width: 768px) {
        height: 179px;
    }
`;

const SmallCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px;
    gap: 30px;

    position: relative;

    width: 276px;
    height: 184px;

    background-color: #ffffff;

    @media screen and (max-width: 768px) {
        width: 315px;
        height: 211px;
    }

    &.want-more {
        background: linear-gradient(
            180deg,
            #ffffff 0%,
            #ffffff 50%,
            #000000 50%,
            #000000 100%
        );
        h1 {
            font-family: "Pretendard Variable";
            font-style: normal;
            font-weight: 900;
            font-size: 28px;
            line-height: 33px;

            color: #000000;
            span.white {
                color: #ffffff;
            }
        }
    }
    &.undefined {
        h1 {
            font-family: "Pretendard Variable";
            font-style: normal;
            font-weight: 900;
            font-size: 28px;
            line-height: 33px;

            color: #000000;
            white-space: pre-line;
        }
    }

    border-radius: 16px;
    transition: 0.2s;

    &:hover {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);

        ${InstaButton} {
            transform: scale(1.25);
            transform-origin: bottom right;
        }
    }
`;

const SmallImageCard = styled.img`
    object-fit: cover;

    width: 276px;
    height: 184px;
    border-radius: 16px;

    transition: 0.2s;

    @media screen and (max-width: 768px) {
        width: 315px;
        height: 211px;
    }

    &:hover {
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);

        ${InstaButton} {
            transform: scale(1.25);
            transform-origin: bottom right;
        }
    }
`;

const ClubSection = styled.section`
    box-sizing: border-box;
    margin: 64px auto;
    gap: 64px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        margin: 30px auto;
        gap: 30px;
    }
`;

const ClubTitleContainer = styled.div`
    position: relative;
    width: 756px;
    height: 149px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 315px;
        height: 111px;
    }
`;

const ClubMainTitle = styled.h1`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 76px;

    color: #000000;

    position: absolute;

    &.ko {
        left: calc(50% - 291px / 2 - 15px);
        top: calc(50% - 76px / 2 - 0.38px);
    }

    &.en {
        left: calc(50% - 417px / 2 - 15px);
        top: calc(50% - 76px / 2 - 0.38px);
    }

    @media screen and (max-width: 768px) {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;

        position: static;
        &.en {
            font-size: 40px;
            line-height: 48px;
        }

        &.ko {
            font-size: 48px;
            line-height: 57px;
        }
    }
`;

const ClubSubTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 38px;

    color: #000000;

    position: absolute;

    &.you {
        left: 14px;
        top: 13.79px;
    }
    &.only {
        display: flex;
        align-items: flex-end;
        right: 0px;
        bottom: 0px;
    }

    strong {
        box-sizing: border-box;
        padding: 2px 8px;
        background: #92b4ec;
        border-radius: 18px;
    }

    @media screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        &.you {
            left: 9px;
            top: 6.79px;
        }
    }
`;

const StarIcon = styled(FontAwesomeIcon)`
    position: absolute;
    left: calc(50% - 16px / 2 - 369px);
    top: 6px;

    transform: rotate(-36deg);

    @media screen and (max-width: 768px) {
        left: 0px;
        top: 0px;
        width: 12px;
        height: 12px;
    }
`;

const Dot = styled.svg`
    position: absolute;
    width: 4px;
    height: 4px;
    right: ${({ position }) =>
        position === "1" ? "235.5px" : position === "2" ? "209.5px" : "auto"};
    bottom: 36px;

    @media screen and (max-width: 768px) {
        right: ${({ position }) =>
            position === "1"
                ? "146.5px"
                : position === "2"
                ? "128.5px"
                : "auto"};
        bottom: 24px;
    }
`;

const Clubs = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: stretch;
    gap: 30px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const Club = styled.li`
    background-image: url(${({ src }) => (src ? src : "")});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    width: 378px;
    height: 277px;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    transition: 0.2s;

    &:hover {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.1);

        ${InstaButton} {
            transform: scale(1.5);
            transform-origin: bottom right;
        }
    }

    @media screen and (max-width: 768px) {
        width: 315px;
        height: 230.83px;

        &:hover {
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            transform: scale(1.05);

            ${InstaButton} {
                transform: scale(1.25);
                transform-origin: bottom right;
            }
        }
    }
`;

function HomePage() {
    const { t, i18n } = useTranslation(["HomePage"]);
    return (
        <StyledMain>
            <BannerSection>
                <Image src="/logo.png" alt="logo" />
                <TitleContainer>
                    <StyledSubTitle className={i18n.language}>
                        {t("sub-title-1")}
                    </StyledSubTitle>
                    <StyledTitle>UCLKS</StyledTitle>
                    <StyledSubTitle className={i18n.language}>
                        {t("sub-title-2")}
                    </StyledSubTitle>
                </TitleContainer>
            </BannerSection>

            <AnnoucementSection>
                <Hanpass>
                    <HanPassTitle>{t("hanpass")}</HanPassTitle>
                    {/* <SmallCard className="want-more">
                        <h1>
                            <span>{t("what")}</span>
                            <br />
                            <span className="white">{t("want-more")}</span>
                        </h1>
                        <InstaButton href="https://www.instagram.com/uclksoc/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </SmallCard> */}
                    <HanPassAnchor href="https://www.instagram.com/p/CllxQJoK9HL/">
                        <SmallImageCard
                            src="/advertisement/hanpass.png"
                            alt="hanpass"
                        ></SmallImageCard>
                    </HanPassAnchor>
                </Hanpass>

                <NextEvent>
                    <NextEventTitle>{t("next-event")}</NextEventTitle>
                    <SmallCard className="undefined">
                        <h1>{t("hmm")}</h1>
                    </SmallCard>
                </NextEvent>

                <ComingEvent>
                    <ComingSoonTitle>{t("dont-miss")}</ComingSoonTitle>
                    <LargeCard className="undefined">
                        {/* <LargeCardImageMask>
                            <LargeCardImage
                                src="/events/freshman-welcome-party-uk.jpeg"
                                alt="freshman-welcome-party-uk"
                            />
                        </LargeCardImageMask> */}
                        {/* <LargeCardContentContainer>
                            <LargeCardContent>
                                <LargeCardContentTitle
                                    className={i18n.language}
                                >
                                    {t("freshman-welcome-party-uk.title")}
                                </LargeCardContentTitle>
                                <LargeCardContentDate>
                                    {t("freshman-welcome-party-uk.date")}
                                </LargeCardContentDate>
                                <LargeCardContentTime>
                                    6:30 PM
                                </LargeCardContentTime>
                                <LargeCardContentLocation>
                                    {t("freshman-welcome-party-uk.location")}
                                </LargeCardContentLocation>
                            </LargeCardContent>
                            <InstaButton
                                href="https://www.instagram.com/p/CjX74KbNFLG/"
                                className="free"
                            >
                                {t("learn-more")}
                                <InstaIcon
                                    icon={faInstagram}
                                    size="2x"
                                ></InstaIcon>
                            </InstaButton>
                        </LargeCardContentContainer> */}
                        <h1>{t("hmm")}</h1>
                    </LargeCard>
                </ComingEvent>
            </AnnoucementSection>

            <ClubSection>
                {i18n.language === "ko" ? (
                    <ClubTitleContainer>
                        <StarIcon icon={faStar} size="1x" />
                        <ClubSubTitle className="you">
                            당신과 어울리는
                        </ClubSubTitle>
                        <ClubMainTitle className="ko">
                            한인 동아리
                        </ClubMainTitle>
                        <ClubSubTitle className="only">
                            오직 UCLKS에서만
                        </ClubSubTitle>
                        <Dot position="1">
                            <circle cx="2" cy="2" r="2" fill="black" />
                        </Dot>
                        <Dot position="2">
                            <circle cx="2" cy="2" r="2" fill="black" />
                        </Dot>
                    </ClubTitleContainer>
                ) : (
                    <ClubTitleContainer>
                        <StarIcon icon={faStar} size="1x"></StarIcon>
                        <ClubSubTitle className="you">
                            Your Suitable
                        </ClubSubTitle>
                        <ClubMainTitle className="en">
                            Korean Clubs
                        </ClubMainTitle>
                        <ClubSubTitle className="only">
                            <strong>Only</strong>at UCLKS
                        </ClubSubTitle>
                    </ClubTitleContainer>
                )}

                <Clubs>
                    <Club src="/basketball-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_basketball/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                    <Club src="/football-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_footballclub/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                    <Club src="/band-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_band/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                    <Club src="/dance-club.jpg">
                        <InstaButton href="https://www.instagram.com/uclks_pretzel/">
                            {t("learn-more")}
                            <InstaIcon icon={faInstagram} size="2x"></InstaIcon>
                        </InstaButton>
                    </Club>
                </Clubs>
            </ClubSection>
        </StyledMain>
    );
}

export default HomePage;
