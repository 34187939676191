const colours = {
    default: {
        SkyBlue: "#92B4EC",
        PureBlack: "#000000",
        PureWhite: "#FFFFFF",
        PaleWhite: "#F2F2F2",
        MistyGrey: "#606060",
        PaleGrey: "#B9C0C8",
        SoftRed: "#FF7171",
        GentleGreen: "#37A693",
        ButteryYellow: "#FFD24C",
    },
};

export const themes = {
    colours,
};
