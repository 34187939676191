import { Link } from "react-router-dom";
import styled from "styled-components";

export const Button = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;

    background-color: ${({ theme: { colours } }) =>
        `${colours.default.SkyBlue}`};
    border-radius: 16px;

    border: none;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    ${({ width }) => (width ? `width:${width}` : "align-self: stretch")};

    color: ${({ theme: { colours } }) => `${colours.default.PureWhite}`};
    cursor: pointer;
    &:enabled {
        &:active {
            background: transparent;
            padding: 5px 16px;
            border: ${({ theme: { colours } }) =>
                `3px solid ${colours.default.SkyBlue}`};
            color: ${({ theme: { colours } }) => `${colours.default.SkyBlue}`};
        }
    }

    &:disabled {
        background-color: ${({ theme: { colours } }) =>
            `${colours.default.PaleGrey}`};
        cursor: not-allowed;
    }

    @media screen and (max-width: 768px) {
        align-self: stretch;
        width: auto;
    }
`;

export const ButtonLink = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    padding: 8px 16px;

    background-color: ${({ theme: { colours } }) =>
        `${colours.default.SkyBlue}`};
    border-radius: 16px;

    border: none;

    ${({ width }) => (width ? `width:${width}` : "align-self: stretch")};

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    text-decoration: none;

    color: ${({ theme: { colours } }) => `${colours.default.PureWhite}`};
    cursor: pointer;

    &:active {
        background: transparent;
        padding: 5px 16px;
        border: ${({ theme: { colours } }) =>
            `3px solid ${colours.default.SkyBlue}`};
        color: ${({ theme: { colours } }) => `${colours.default.SkyBlue}`};
    }

    &:disabled {
        background-color: ${({ theme: { colours } }) =>
            `${colours.default.PaleGrey}`};
        cursor: not-allowed;
    }

    @media screen and (max-width: 768px) {
        align-self: stretch;
        width: auto;
    }
`;
