import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getModule, getModuleAverage } from "../actions/moduleActions";
import {
    faAngleDown,
    faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
    MODULE_AVERAGE_RESET,
    MODULE_RESET,
} from "../constants/moduleConstants";
import Ratings from "../components/Ratings.styled";
import CommentBox from "../components/CommentBox";
import OptionSelect from "../components/Styles/OptionSelect";
import Info from "../components/Info";
import { useTranslation } from "react-i18next";
import { RecentOrder } from "../components/Dropwdown";

const StyledMain = styled.main`
    box-sizing: border-box;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;

    @media screen and (max-width: 768px) {
        margin: 30px;
        padding: 0;
        gap: 30px;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 1194px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
        width: 315px;
    }
`;

const Title = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 32px;

    background: #92b4ec;
    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    text-align: left;

    color: #ffffff;

    background: #92b4ec;
    border-radius: 16px;

    align-self: stretch;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

const TitleInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    align-self: stretch;
    flex-grow: 0;

    @media screen and (max-width: 768px) {
        flex-direction: row;
        gap: 15px;
        width: auto;
        height: auto;
    }
`;

const TitleInfoLearnMore = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 32px;
    gap: 16px;

    background: #92b4ec;
    border-radius: 16px;

    align-self: stretch;
    flex-grow: 1;

    text-decoration: none;

    width: 201px;

    h2 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;

        color: #ffffff;
    }

    @media screen and (max-width: 768px) {
        gap: 15px;
        padding: 16px;
        width: auto;

        h2 {
            font-size: 18px;
            line-height: 21px;
        }
    }
`;

const TitleInfoCode = styled.h2`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 16px 32px;

    background: #f2f2f2;
    border-radius: 16px;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    color: #000000;

    align-self: stretch;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 21px;
        padding: 16px;
    }
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
`;

const Icon = styled(FontAwesomeIcon)`
    width: ${({ dimension }) => (dimension ? dimension : "32px")};
    height: ${({ dimension }) => (dimension ? dimension : "32px")};
    color: ${({ colour }) => (colour ? colour : "#ffffff")};

    position: relative;

    @media screen and (max-width: 768px) {
        width: ${({ dimension }) => (dimension ? dimension : "16px")};
        height: ${({ dimension }) => (dimension ? dimension : "16px")};
    }
`;

const AverageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    h1 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 48px;

        color: #000000;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;

        width: 315px;

        h1 {
            font-size: 24px;
            line-height: 29px;
        }
    }
`;

const Averages = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    box-sizing: border-box;

    width: 1194px;

    background: #f2f2f2;
    border-radius: 16px;

    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        padding: 16px 32px;
        gap: 15px;

        width: 315px;
    }
`;

const Average = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;

    h2 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;

        color: #000000;
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-weight: 800;
            font-size: 18px;
            line-height: 21px;
        }
    }
`;

const FeedbackContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    width: 582px;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        width: 315px;
    }
`;

const FeedbackTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    align-self: stretch;
`;

const FeedbackTitleBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 768px) {
        gap: 15px;
    }
`;

const FeedbackTitle = styled.h2`
    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;

    color: #000000;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 29px;
    }
`;

const OptionSelectCurrent = styled.h2`
    text-align: right;

    font-family: "Pretendard Variable";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
    width: ${({ width }) => (width ? width : "auto")};

    @media screen and (max-width: 768px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        width: auto;
    }
`;

const Cards = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    gap: 30px;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 15px;
    }
`;

const Card = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;

    box-sizing: border-box;
    padding: 32px;
    gap: 30px;

    background: #f2f2f2;
    border-radius: 16px;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        padding: 16px 32px;
        gap: 15px;
    }
`;

const CardRatings = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 26px;

    position: relative;
    align-self: stretch;

    @media screen and (max-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        gap: 15px;
    }
`;

const CardRating = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    h3 {
        font-family: "Pretendard Variable";
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;

        color: #000000;

        &.en {
            font-size: 18px;
            line-height: 21px;
        }
    }

    @media screen and (max-width: 768px) {
        h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
        }
    }
`;

const CommentsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;

    align-self: stretch;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
    }
`;

function FeedbackPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = useLocation().pathname.split("/")[5];
    const recent = new URLSearchParams(useLocation().search).get("recent");
    const module = useSelector((state) => state.module);
    const { module: moduleInfo } = module;
    const moduleAverage = useSelector((state) => state.moduleAverage);
    const { average } = moduleAverage;
    const { t, i18n } = useTranslation("FeedbackPage");

    useEffect(() => {
        dispatch(getModule(id, recent));
        return () => {
            dispatch({ type: MODULE_RESET });
        };
    }, [dispatch, id, recent]);

    useEffect(() => {
        dispatch(getModuleAverage(id));
        return () => {
            dispatch({ type: MODULE_AVERAGE_RESET });
        };
    }, [dispatch, id]);

    useEffect(() => {
        {
            if (recent === null || recent !== "true" || recent !== "false") {
                navigate({
                    pathname: "",
                    search: `?${createSearchParams({
                        recent: true,
                    })}`,
                });
            }
        }
    }, []);

    return (
        <StyledMain>
            <TitleContainer>
                <Title>{moduleInfo?.name}</Title>
                <TitleInfoContainer>
                    <TitleInfoLearnMore
                        href={`https://www.ucl.ac.uk/module-catalogue/modules/${moduleInfo?.code}`}
                    >
                        <h2>{t("module-info")}</h2>
                        <Icon icon={faArrowRightLong} />
                    </TitleInfoLearnMore>
                    <TitleInfoCode>{moduleInfo?.code}</TitleInfoCode>
                </TitleInfoContainer>
            </TitleContainer>
            <MainContainer>
                <AverageContainer>
                    <h1>{t("overall-average")}</h1>
                    <Averages>
                        <Average>
                            <h2>{t("ratings.evaluation")}</h2>
                            <Ratings rating={average?.evaluation / 2} />
                        </Average>
                        <Average>
                            <h2>{t("ratings.module-difficulty")}</h2>
                            <Ratings rating={average?.module_difficulty} />
                        </Average>
                        <Average>
                            <h2>{t("ratings.amount-of-assignments")}</h2>
                            <Ratings rating={average?.amount_of_assignments} />
                        </Average>
                        <Average>
                            <h2>{t("ratings.exam-difficulty")}</h2>
                            <Ratings rating={average?.exam_difficulty} />
                        </Average>
                    </Averages>
                </AverageContainer>

                <FeedbackContainer>
                    <FeedbackTitleContainer>
                        <FeedbackTitleBox>
                            <FeedbackTitle>{t("feedback")}</FeedbackTitle>
                            <RecentOrder order={recent} />
                            {/* <OptionSelect.Container>
                                <OptionSelect.Button
                                    type="button"
                                    className={isOpen ? "clicked" : ""}
                                    onClick={(e) =>
                                        setIsOpen((current) => !current)
                                    }
                                >
                                    <OptionSelectCurrent>
                                        {recent === true
                                            ? t("recent")
                                            : t("oldest")}
                                    </OptionSelectCurrent>
                                    <OptionSelect.Icon
                                        icon={faAngleDown}
                                        className="flip"
                                        size="2xl"
                                    />
                                </OptionSelect.Button>
                                <OptionSelect.Options
                                    className={isOpen ? "active" : ""}
                                >
                                    <OptionSelect.Option>
                                        <OptionSelect.OptionText
                                            onClick={(e) =>
                                                handleOptionClick(true)
                                            }
                                        >
                                            {t("recent")}
                                        </OptionSelect.OptionText>
                                        <OptionSelect.Divider />
                                    </OptionSelect.Option>
                                    <OptionSelect.Option>
                                        <OptionSelect.OptionText
                                            onClick={(e) =>
                                                handleOptionClick(false)
                                            }
                                        >
                                            {t("oldest")}
                                        </OptionSelect.OptionText>
                                    </OptionSelect.Option>
                                </OptionSelect.Options>
                            </OptionSelect.Container> */}
                        </FeedbackTitleBox>
                    </FeedbackTitleContainer>
                    <Cards>
                        {moduleInfo?.feedback?.map((eachFeedBack) => {
                            return (
                                <Card key={eachFeedBack.id}>
                                    <CardRatings>
                                        <CardRating>
                                            <h3 className={i18n.language}>
                                                {t("ratings.evaluation")}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    eachFeedBack["evaluation"] /
                                                    2
                                                }
                                                dimension="16px"
                                            />
                                        </CardRating>
                                        <CardRating>
                                            <h3 className={i18n.language}>
                                                {t("ratings.module-difficulty")}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    eachFeedBack[
                                                        "module_difficulty"
                                                    ]
                                                }
                                                dimension="16px"
                                            />
                                        </CardRating>
                                        <CardRating>
                                            <h3 className={i18n.language}>
                                                {t(
                                                    "ratings.amount-of-assignments"
                                                )}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    eachFeedBack[
                                                        "amount_of_assignments"
                                                    ]
                                                }
                                                dimension="16px"
                                            />
                                        </CardRating>
                                        <CardRating>
                                            <h3 className={i18n.language}>
                                                {t("ratings.exam-difficulty")}
                                            </h3>
                                            <Ratings
                                                rating={
                                                    eachFeedBack[
                                                        "exam_difficulty"
                                                    ]
                                                }
                                                dimension="16px"
                                            />
                                        </CardRating>
                                    </CardRatings>
                                    <CommentsContainer>
                                        <CommentBox
                                            text={eachFeedBack["tips"]}
                                            type="tips"
                                        />
                                        <CommentBox
                                            text={eachFeedBack["comments"]}
                                            type="comments"
                                        />
                                    </CommentsContainer>
                                </Card>
                            );
                        })}
                    </Cards>
                </FeedbackContainer>
            </MainContainer>
            <Info />
        </StyledMain>
    );
}

export default FeedbackPage;
